import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { GiFilmSpool } from "react-icons/gi";
import { BsStarFill } from "react-icons/bs";
import { Section } from "./";

const Poster = ({ url }) => {
  return url ? (
    <PosterImage data-src={url} className="lazyload" />
  ) : (
    <NoPoster>
      <GiFilmSpool />
    </NoPoster>
  );
};

const PosterImage = styled.img`
  //display: flex;
  width: 100px;
  height: 150px;
  //border-radius: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1); // TODO: move this should be in a css var
  object-fit: cover;
`;

const NoPoster = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;

  width: 100px;
  height: 150px;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(var(--color-blue-rgb), 0.5);
  color: var(--color-charcoal);
`;

export default function PosterScroll({ icon, title, movies }) {
  if (!movies || movies.length === 0) return null;
  return (
    <Section title={title} icon={icon}>
      <HorizontalScroll>
        {movies?.map((movie) => {
          const { slug, imdb_id, title, poster_url, imdb_rating_avg } = movie;
          return (
            <ScrollLink key={imdb_id} to={`/movie/${slug}`}>
              <Poster url={poster_url} />
              <Title>{title}</Title>
              {imdb_rating_avg && (
                <Rating>
                  <BsStarFill />
                  <span>{imdb_rating_avg}</span>
                </Rating>
              )}
            </ScrollLink>
          );
        })}
      </HorizontalScroll>
    </Section>
  );
}

const HorizontalScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ScrollLink = styled(Link)`
  //display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
  max-width: 100px;
  position: relative;
`;

const Title = styled.p`
  font-size: 0.75rem;
  overflow: hidden; // want '...'
  line-height: 1rem;
  height: 2rem;
  margin-top: 0.25rem;
  color: lightgray;
`;

const Rating = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;

  //border: 1px solid orange;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.75rem;
  svg {
    //font-size: 0.8rem;
    margin-right: 0.125rem;
    color: lightgray;
  }

  span {
  }
`;
