import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { BsStarFill } from "react-icons/bs";
import { FiStar } from "react-icons/fi";
import { Section, ImdbMovieLink, TheMovieDbMovieLink } from "./";

export default function Ratings({ movie }) {
  const {
    imdb_rating_avg,
    imdb_rating_count,
    tmdb_rating_avg,
    tmdb_rating_count,
  } = movie;

  return (
    <WrapOnLarge>
      <Section title="Ratings" icon={<FiStar />}>
        <Wrap>
          <Rating>
            <Icon>
              <BsStarFill />
            </Icon>
            <Average>{imdb_rating_avg || "0.0"}</Average>
            <Title>
              <ImdbMovieLink movie={movie}>IMDb</ImdbMovieLink>
            </Title>
            <Count>{imdb_rating_count?.toLocaleString() || "0"}</Count>
          </Rating>
          <Rating>
            <Icon>
              <BsStarFill />
            </Icon>
            <Average>{tmdb_rating_avg || "0.0"}</Average>
            <Title>
              <TheMovieDbMovieLink movie={movie}>TMDb</TheMovieDbMovieLink>
            </Title>
            <Count>{tmdb_rating_count?.toLocaleString() || "0"}</Count>
          </Rating>
        </Wrap>
      </Section>
    </WrapOnLarge>
  );
}

const WrapOnLarge = styled.div`
  display: flex;

  @media ${device.min.tablet} {
    width: 50%;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-around;

  @media ${device.min.small} {
    justify-content: flex-start;
  }
`;

const Rating = styled.div`
  width: 140px;
  height: 60px;
  display: grid;
  grid-template-areas:
    "icon average"
    "title count";
  grid-template-rows: 1.5fr 1fr;
  grid-template-columns: 60px 1fr;
  color: white;

  :last-of-type {
    margin-left: 1rem;
  }
`;

const Icon = styled.span`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  color: rgba(var(--color-blue-rgb), 0.85);
`;

const Average = styled.span`
  grid-area: average;
  align-self: center;
  font-size: 1.75rem;
  font-weight: 700;
`;

const Title = styled.span`
  grid-area: title;
  justify-self: center;
  align-self: center;
  font-size: 1rem;
`;

const Count = styled.span`
  grid-area: count;
  align-self: center;
  font-size: 1rem;
  color: lightgray;
`;
