import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components/macro";
import { GlobalStyles } from "./GlobalStyles";
import {
  Home,
  List,
  ReleaseDates,
  Discover,
  MovieDetail,
  Search,
  Details,
  Favorites,
  Upcoming,
  Register,
  Login,
  Logout,
  Actor,
  Providers,
  ProviderDetail,
  Keywords,
  KeywordDetail,
  ProductionCompanies,
  ProductionCompanyDetail,
  NotFound,
} from "./pages";
import { HeaderWithSearch, PrivateRoute, ScrollTop } from "./components";
import { AuthProvider } from "./hooks";
import { appPaths } from "./constants/appPaths";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Wrap>
        <GlobalStyles />
        <AuthProvider>
          {/*<BrowserRouter basename="/mmdb">*/}
          <BrowserRouter>
            <ScrollTop />
            <HeaderWithSearch />
            <Routes>
              {/* Home */}
              <Route exact path={appPaths.home} element={<Home />} />
              {/* Lists */}
              <Route path={"/list/:slug"} element={<List />} />

              <Route path={"/release-dates/:type"} element={<ReleaseDates />} />
              <Route path={"/release-dates"} element={<ReleaseDates />} />
              <Route path={"/discover"} element={<Discover />} />
              <Route path={"/search"} element={<Search />} />

              {/* Actor */}
              <Route path="/actor/:slug" element={<Actor />} />

              {/* Provider */}
              <Route path="/provider" element={<Providers />} />
              <Route path="/provider/:slug" element={<ProviderDetail />} />

              {/* Keyword */}
              <Route path="/keyword" element={<Keywords />} />
              <Route path="/keyword/:slug" element={<KeywordDetail />} />

              {/* ProductionCompanies */}
              <Route
                path="/production-company"
                element={<ProductionCompanies />}
              />
              <Route
                path="/production-company/:slug"
                element={<ProductionCompanyDetail />}
              />

              {/* MovieDetail */}
              <Route path="/movie/:slug" element={<MovieDetail />} />

              {/* Auth */}
              <Route path={appPaths.register} element={<Register />} />
              <Route path={appPaths.login} element={<Login />} />
              <Route
                path={appPaths.logout}
                element={
                  <PrivateRoute>
                    <Logout />
                  </PrivateRoute>
                }
              />
              {/* User */}
              <Route
                path={appPaths.user}
                element={
                  <PrivateRoute>
                    <Details />
                  </PrivateRoute>
                }
              />
              <Route
                path={appPaths.userFavorites}
                element={
                  <PrivateRoute>
                    <Favorites />
                  </PrivateRoute>
                }
              />
              <Route
                path={appPaths.userUpcoming}
                element={
                  <PrivateRoute>
                    <Upcoming />
                  </PrivateRoute>
                }
              />

              <Route path={"*"} element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Wrap>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const Wrap = styled.div`
  width: 100vw;
  max-width: var(--max-content-width);
  min-width: 350px;
  //height: 100vh; // no
  margin-left: auto;
  margin-right: auto;
  background: var(--color-charcoal);
`;
