import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { BiUser } from "react-icons/bi";
import { Section } from "./";
import React from "react";

const getActorInitials = (name) => {
  if (!name) return "";
  return name
    .match(/(\b\w)?/g)
    .join("")
    .toUpperCase();
};

const Poster = ({ url, name }) => {
  return url ? (
    <CreditPoster data-src={url} className="lazyload" alt={name} />
  ) : (
    <NoCreditPoster>{getActorInitials(name)}</NoCreditPoster>
  );
};

export default function Credits({ movie }) {
  const { credits } = movie;
  return (
    <Section title="Credits" icon={<BiUser />}>
      <HorizontalScroll>
        {credits?.map((item) => {
          const { order, character, name, profile_url, slug } = item;
          return (
            <ScrollItem key={order}>
              <Poster url={profile_url} name={name} />
              {/*<ActorTag>{name}</ActorTag>*/}
              <ActorTag>
                <Link to={`/actor/${slug}`}>{name}</Link>
              </ActorTag>
              <CharacterTag>{character}</CharacterTag>
            </ScrollItem>
          );
        })}
      </HorizontalScroll>
    </Section>
  );
}

/* what to do with poster or missing poster wrap */

const NoCreditPoster = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 1.75rem;

  width: 100px;
  height: 100px;
  //border-radius: 50%;
  border-radius: 0.75rem;
  overflow: hidden;
  color: whitesmoke;
  background: rgba(var(--color-blue-rgb), 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1); // TODO: move this should be in a css var
`;

const CreditPoster = styled.img`
  display: flex;
  width: 100px;
  height: 100px;
  //border-radius: 50%;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1); // TODO: move this should be in a css var
  object-fit: cover;
`;

//const ActorTag = styled(Link)`
const ActorTag = styled.p`
  height: 2rem;
  line-height: 1rem;
  font-size: 0.75rem;
  text-align: center;
  overflow: hidden;
  color: whitesmoke;
`;

const CharacterTag = styled(ActorTag)`
  color: lightgray;
`;

const HorizontalScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ScrollItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
`;
