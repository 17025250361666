import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { BiBookAlt } from "react-icons/bi";
import { Section } from "./";

export default function Overview({ movie }) {
  const { overview } = movie;
  return (
    overview && (
      <Section title="Overview" icon={<BiBookAlt />}>
        <OverviewWrap>{overview}</OverviewWrap>
      </Section>
    )
  );
}

const OverviewWrap = styled.p`
  line-height: 1.5rem;
  font-size: 1rem;
  color: white;
  max-width: 700px;
  padding: 8px; // ?
`;
