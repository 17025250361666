const endpoints = {
  movie: {
    all: `/movie/`,
    detail: (slug) => `/movie/${slug}/`,
  },
  actor: {
    detail: (slug) => `/actor/${slug}/`,
    movies: (slug) => `/actor/${slug}/movies/`,
  },
  list: {
    movies: (slug) => `/list/${slug}/`,
  },
  keyword: {
    all: `/keyword/`,
    detail: (slug) => `/keyword/${slug}/`,
    movies: (slug) => `/keyword/${slug}/movies/`,
  },
  productionCompany: {
    all: `/production-company/`,
    detail: (slug) => `/production-company/${slug}/`,
    movies: (slug) => `/production-company/${slug}/movies/`,
  },
  provider: {
    all: `/provider/`,
    detail: (slug) => `/provider/${slug}/`,
    movies: (slug) => `/provider/${slug}/movies/`,
  },
};

export default endpoints;
