import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { LoaderIcon } from "./Icons";

const spinLoader = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 20px;
  //height: 20px;
  //background: yellow;
  animation: ${spinLoader} 2000ms linear infinite;
`;

const Loader = ({ size }) => {
  return (
    <Wrap>
      <LoaderIcon size={size || "1em"} />
    </Wrap>
  );
};

export { Loader };
