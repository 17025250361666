import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
  formatTagDate,
  eachPeriodOfInterval,
  dateToUrl,
} from "../../../hooks/dates";
import { useRenderCount } from "../../../hooks";
import { ArrowLeft, ArrowRight } from "../../../components/Icons";

import { Button } from "../../../components/Toolbar";
import { device } from "../../../constants/devices";

const makeDatePagerObject = (date, period) => {
  const datesList = eachPeriodOfInterval(date, period, 1);
  return {
    prev: dateToUrl(datesList[0]),
    current: dateToUrl(datesList[1]),
    next: dateToUrl(datesList[2]),
  };
};

const useDates = ({ date, period }) => {
  const [dates, setDates] = useState(() => makeDatePagerObject(date, period));

  useEffect(() => {
    setDates(makeDatePagerObject(date, period));
  }, [date, period]);

  return { dates };
};

export default function Filters({
  date,
  onDateChange,
  period,
  onPeriodChange,
  types,
  goToCurrentPeriod,
  selected,
}) {
  useRenderCount("Filters");
  const { dates } = useDates({ date, period });

  const goTo = (type) =>
    type === selected ? `/release-dates` : `/release-dates/${type}`;

  return (
    <Wrap>
      <Bar>
        <Title>Type</Title>
        {(types || []).map((type) => (
          <Tab key={type} to={goTo(type)} isSelected={type === selected}>
            {type}
          </Tab>
        ))}

        <ToggleButton onClick={() => onPeriodChange(period)}>
          by {period}
        </ToggleButton>
      </Bar>

      <ButtonWrap>
        <PageButton onClick={() => onDateChange(dates.prev)}>
          <ArrowLeft />
        </PageButton>
        <Date
          key={formatTagDate(dates.current, period)}
          onClick={goToCurrentPeriod}
        >
          {formatTagDate(dates.current, period)}
        </Date>
        <PageButton onClick={() => onDateChange(dates.next)}>
          <ArrowRight />
        </PageButton>
      </ButtonWrap>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  //align-items: center;
  margin-top: 0.75rem;
  gap: 0.75rem;
  //height: 40px;
  //width: 100%;
  //max-width: 400px;

  @media ${device.min.tablet} {
    flex-direction: row-reverse;
    justify-content: space-between;
    column-gap: 0.75rem;
  }
`;

const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  //max-width: 400px;

  @media ${device.min.tablet} {
    justify-content: center;
    column-gap: 0.75rem;
  }
`;

const Title = styled.h3`
  display: none;
  color: gray;
  font-size: 1rem;
  font-weight: 400;
  //margin-right: auto;

  @media ${device.min.desktop} {
    display: flex;
    justify-self: flex-start;
  }
`;

// const Tab = styled.button`
const Tab = styled(Link)`
  display: flex;
  //margin: 0 0.25rem;
  margin-right: 0.25rem;
  border-radius: 0.75rem;
  text-transform: capitalize;

  padding: ${({ isSelected }) =>
    isSelected ? "0.25rem 0.75rem" : "0.25rem 0.75rem"};

  background: ${({ isSelected }) =>
    isSelected ? "rgba(var(--color-blue-rgb), 0.75)" : "whitesmoke"};
  border: ${({ isSelected }) =>
    isSelected ? "1px solid var(--color-blue)" : "1px solid lightgray"};

  &:visited {
    color: ${({ isSelected }) =>
      isSelected ? "white" : "var(--color-charcoal)"};
  }
  padding: 0.25rem 0.5rem;

  @media ${device.min.tablet} {
    padding: 0.25rem 0.75rem;
  }
`;

const ButtonWrap = styled.div`
  //border: 1px solid orange;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.min.tablet} {
    width: 300px;
    align-self: center;
  }
`;

const Date = styled.h3`
  //flex: 1;
  //margin-left: auto;
  //margin-right: auto;
  //padding: 0.375rem;

  //text-transform: capitalize;
  //border: 1px solid orange;
`;

const PageButton = styled(Button)`
  margin: 0;
`;

const ToggleButton = styled(Button)`
  min-width: 75px;
  margin-left: auto;
  text-transform: capitalize;
`;

// const Button = styled.button`
//   border: 1px solid lightgray;
//   margin: 0 0 0 0.5rem;
//   padding: 0.375rem;
//
// text-transform: capitalize;
// `;
