import React from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../hooks";

import Poster from "./Poster";
import FavoriteButton from "../MovieListItem/FavoriteButton";
import RefreshButton from "../MovieListItem/RefreshButton";

export default function PosterItem({ movie, dateType }) {
  const { slug, imdb_id, poster_url } = movie;
  const { user } = useAuth();

  return (
    <Wrap>
      <Poster poster_url={poster_url} slug={slug} />
      {user && <FavoriteButton imdb_id={imdb_id} />}
      {user && <RefreshButton imdb_id={imdb_id} />}
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;
  height: 100%;
`;
