import React, { createContext, useContext } from "react";
import API, { getCsrfToken } from "../api/api";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useRenderCount } from "./useRenderCount";
import { useLocalStorage } from "./";

// NOTES: axios throws errors by itself (fetch does not)

const authUrls = {
  setcsrf: "auth/set-csrf/",
  signup: "auth/register/",
  signin: "auth/login/",
  signout: "auth/logout/",
  userDetails: "user/",
  // userFavorites: "user/favorites/",
  // userUpcoming: "user/upcoming/",
  // favorite: (imdb_id) => `/movies/${imdb_id}/favorite/`, // <str:imdb_id>
  // refresh: (imdb_id) => `/movies/${imdb_id}/refresh/`,
};

const AuthContext = createContext();

function AuthProvider({ children }) {
  useRenderCount("AuthProvider");
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

// const getUser = async () => {
//   try {
//     const response = await API.get(authUrls.userDetails);
//     console.debug("useAuth:getUser: response: ", response);
//     return response.data;
//   } catch (error) {
//     if (error.response.status === 403) {
//       console.error("useAuth:useQuery:user:error (403): ", error);
//       return null;
//     }
//     return error;
//   }
// };

const getUser = async () => {
  const response = await API.get(authUrls.userDetails);
  console.debug("useAuth:getUser: response: ", response);
  return response.data;
};

function useProvideAuth() {
  // useRenderCount("useProvideAuth", true);
  const queryClient = useQueryClient();

  // const getUsername = () => {
  //   const username = localStorage.getItem("username");
  // console.log("getUsername: ", username);
  // console.log("getUsername: username ?? false", username || false);
  // return !!username;
  // };

  const {
    data: user,
    isFetching: isFetchingUser,
    refetch: refetchUser,
  } = useQuery(["user"], getUser, {
    enabled: !!localStorage.getItem("username") || false, // or? ??
    // enabled: getUsername(),
    retry: 0,
    onSuccess: (data) => {
      console.log("useAuth: useQuery['user']: onSuccess: ", data);
    },
    onError: (error) => {
      console.log("useAuth: useQuery['user']: onError: ", error);
      queryClient.removeQueries(["user"]);
      localStorage.removeItem("username");
    },
  });

  React.useEffect(() => {
    const setCSRF = async () => await API.get(authUrls.setcsrf);
    // if (!getCsrfToken()) {
    // TODO: replace this check
    setCSRF().then((val) => console.log(`set CSRF token`));
    // }
  }, []);

  const signup = useMutation(
    async ({ username, password, password2, email = "" }) => {
      const data = { username, password, password2, email };
      const response = await API.post(authUrls.signup, data);
      // console.log(`useAuth:userSignup: response: `, response);
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log("useAuth: signup: onSuccess: data: ", data);
        queryClient.setQueryData(["user"], data);
        localStorage.setItem("username", data.username);
      },
    }
  );

  const signin = useMutation(
    async ({ username, password, remember }) => {
      const response = await API.post(authUrls.signin, {
        username,
        password,
        remember,
      });
      console.log(`useAuth: userSignin: response: `, response);
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["user"], data);
        sessionStorage.setItem("username", data.username);
        localStorage.setItem("username", data.username);
      },
    }
  );

  const signout = useMutation(
    async () => {
      const response = await API.post(authUrls.signout);
      console.log(`useAuth:userSignout: response: `, response);
      return response.data; // return null;
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(["user"]);
        sessionStorage.removeItem("username");
        localStorage.removeItem("username");
      },
    }
  );

  return {
    signup,
    signin,
    signout,
    user,
    isFetchingUser,
    refetchUser,
  };
}

export { useAuth, AuthProvider };

/*
 For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
