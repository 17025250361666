import { useState, useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import { StarRatingIcon, IsStarRatingIcon, CloseIcon } from "../../Icons";
import { motion, AnimatePresence } from "framer-motion";

const StarItem = ({ value, isSelected, onClick }) => {
  return isSelected ? (
    <IsStarRatingIcon onClick={() => onClick(value)} />
  ) : (
    <StarRatingIcon onClick={() => onClick(value)} />
  );
};

export default function RatingBar({ isOpen, toggleBar, movie }) {
  const { slug, title, imdb_rating_avg } = movie;
  const [tempRating, setTempRating] = useState(parseInt(imdb_rating_avg));
  const MAX_RATING = 10;

  const ratingArray = useMemo(() => {
    let temp = [];
    for (let i = 1; i <= MAX_RATING; i++) {
      temp.push({
        value: i,
        isSelected: i <= tempRating,
      });
    }
    return temp;
  }, [tempRating]); // user_rating

  const onChange = (value) => {
    setTempRating(value);
  };

  useEffect(() => {
    console.log("tempRating", tempRating);
  }, [tempRating]);

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledRatingBar
          key="backdrop"
          variants={menuVariants}
          initial="closed"
          animate="open"
        >
          <Title>{title}</Title>
          <CloseButton onClick={toggleBar}>
            <CloseIcon />
          </CloseButton>
          {ratingArray.map(({ value, isSelected }) => {
            return (
              <StarItem
                key={value}
                value={value}
                isSelected={isSelected}
                onClick={onChange}
              />
            );
          })}
        </StyledRatingBar>
      )}
    </AnimatePresence>
  );
}

const menuVariants = {
  closed: {
    visibility: "hidden",
    opacity: 0.75,
    width: "0",
    // transform: "scale(0.95)",
  },
  open: {
    // zIndex: 1,
    visibility: "visible",
    opacity: 1,
    // width: "320px",
    width: "calc(100% - 0.25rem)",
    // transform: "scale(1.00)",
  },
};

const StyledRatingBar = styled(motion.div)`
  border: 1px solid lightgray;
  background: whitesmoke;
  position: absolute;
  //z-index: 10;
  right: 0;
  top: 0;
  display: flex;

  height: calc(100% - 0.25rem);
  justify-content: space-evenly;
  align-items: center;
  margin: 0.125rem;
  transform-origin: right;
  border-radius: 0.25rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`;

const Title = styled.p`
  position: absolute;
  top: 0.25rem;
`;
