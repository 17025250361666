import { useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useRenderCount } from "./useRenderCount";

const qsOptions = {
  arrayFormat: "comma",
  skipNull: true,
  skipEmptyString: true,
  parseNumbers: true,
  sort: false,
};

const paramsToObj = (params) => {
  return qs.parse(params, qsOptions);
};

const paramsToString = (params) => {
  return qs.stringify(params, qsOptions);
};

// Used to sync the url and local component state (source of truth is the url)
function useQueryParams(requiredParams = null) {
  useRenderCount("useQueryParams");
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return requiredParams
      ? { ...requiredParams, ...paramsToObj(location.search) }
      : { ...paramsToObj(location.search) };
  }, [requiredParams, location.search]);

  const updateQueryParams = useCallback(
    (params, replace = false) => {
      // keep browser history clean, allows back/forward navigation
      if (location?.search !== `?${paramsToString(params)}`) {
        const toPath = location.pathname + "?" + paramsToString(params);
        !location.search
          ? navigate(toPath, { replace: true })
          : navigate(toPath, { replace: replace });
      }
    },
    [location.search, location.pathname, navigate]
  );

  useEffect(() => {
    if (requiredParams) {
      const newParams = { ...requiredParams, ...paramsToObj(location.search) };
      updateQueryParams(newParams, true);
    }
  }, [requiredParams, location.search, updateQueryParams]);

  return [queryParams, updateQueryParams];
}

export { useQueryParams };
