import { useQuery } from "react-query";
import API from "../api";

const useApi = ({ path, queryParams, requestParams, enabled = true }) => {
  const params = { ...queryParams, ...requestParams };
  return useQuery(
    [path, params],
    async () => {
      const response = await API({
        method: "get",
        url: path,
        params: params,
        // params: { ...params },
      });
      console.debug(`useApi (${path}): response: `, response);
      return response.data;
    },
    {
      enabled: enabled,
    }
  );
};

export default useApi;
