import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

import Modal from "./Modal";
import ActiveFilters from "./ActiveFilters";
import FilterMenu from "./FilterMenu";

export default function Filters({ show, filters, onApply, onClose }) {
  // const {} = useFilters({});
  return (
    <>
      <ActiveFilters filters={filters} />
      {/* tablet/desktop filter menu */}

      <MenuWrap isOpen={show}>
        <FilterMenu
          isOpen={show}
          filterState={filters}
          onApplyFilters={onApply}
          onClose={onClose}
        />
      </MenuWrap>
      {/* mobile filter menu */}
      {/*{show && (*/}
      <Modal title="Filters" isOpen={show} onClose={onClose}>
        <FilterMenu
          isOpen={show}
          filterState={filters}
          onApplyFilters={onApply}
          onClose={onClose}
        />
      </Modal>
      {/*)}*/}
    </>
  );
}

const MenuWrap = styled.div`
  display: none;

  @media ${device.min.tablet} {
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 0.25rem;
    max-height: ${({ isOpen }) => (isOpen ? "750px" : 0)};
    transition: max-height 200ms cubic-bezier(0, 1, 0.5, 1);
  }
`;
