import React from "react";
import { Range, getTrackBackground } from "react-range";

export default function RangeSlider({ value, min, max, step, onChange }) {
  const colors =
    value.length === 1
      ? ["var(--color-blue)", "#ccc"]
      : ["#ccc", "var(--color-blue)", "#ccc"];
  return (
    <Range
      min={min}
      max={max}
      step={step}
      values={value}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            // height: "36px",
            height: "24px",
            display: "flex",
            // width: "100%",
            width: "calc(100% - 24px)",
            alignSelf: "center",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "4px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: value,
                colors: colors,
                min: min,
                max: max,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props, isDragged }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "24px",
            width: "24px",
            borderRadius: "4px",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 1px 4px #aaa",
          }}
        >
          <div
            style={{
              height: "12px",
              width: "4px",
              backgroundColor: isDragged ? "var(--color-blue)" : "#ccc",
            }}
          />
        </div>
      )}
    />
  );
}
