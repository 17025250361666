import axios from "axios";
import qs from "query-string";
import Cookies from "js-cookie";

// TODO: put in .env file
// const baseApiUrl = "https://matthewhopps.com/api";
// const baseApiUrl = "http://143.110.158.183:8000/api";
// const baseApiUrl = "https://api.mhstuff.xyz/api";

// const baseApiUrl = "https://django.mhstuff.xyz/api";
const baseApiUrl = "https://django.matthewhopps.com/api/";
// const baseApiUrl = "http://10.0.1.111:8008/api";

export const getCsrfToken = () => Cookies.get("csrftoken");

export default axios.create({
  baseURL: baseApiUrl,
  timeout: 2500,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
    "X-CSRFTOKEN": getCsrfToken(),
  },
  xsrfCookieName: "csrftoken", // django defaults
  xsrfHeaderName: "X-CSRFTOKEN", // django defaults
  withCredentials: true, // TODO: move this out, only use for auth requests?
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      // arrayFormat: "comma",
      skipNull: true,
      skipEmptyString: true,
      parseNumbers: true,
      sort: false,
    });
  },
});
