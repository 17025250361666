import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import { useAuth, useMutateMovie } from "../../../hooks";
import { Loader } from "../../Loaders";
import { FavoriteIcon, IsFavoriteIcon } from "../../Icons";

export default function FavoriteButton({ slug }) {
  const { favorite } = useMutateMovie();
  const { user } = useAuth();
  const { favorites } = user;

  const [isFav, setIsFav] = useState(() => favorites?.includes(slug));

  const toggleFav = async () => await favorite.mutateAsync({ isFav, slug });

  useEffect(() => {
    setIsFav(favorites?.includes(slug));
  }, [favorites, slug, setIsFav]);

  return (
    <Button onClick={(e) => toggleFav(e)} isFav={isFav}>
      {favorite.isLoading ? (
        <Loader />
      ) : isFav ? (
        <IsFavoriteIcon />
      ) : (
        <FavoriteIcon />
      )}
    </Button>
  );
}

const Button = styled.button`
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  background: none;
  opacity: 0.75;
  color: ${({ isFav }) =>
    isFav ? "var(--color-blue)" : "var(--color-charcoal-light)"};
  padding: 0.375rem;
  border-radius: 50%;

  :hover {
    color: white;
    background: rgba(var(--color-blue-rgb), 0.95);
  }
`;
