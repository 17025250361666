import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export default function NotFound() {
  console.log("Not Found Page");

  return (
    <Wrap>
      <div>Not Found</div>
      <Link to={"/"}>Go Home</Link>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-top: 2rem;
  color: white;
`;
