import styled from "styled-components/macro";

export default function CheckBoxGroup({ options, selected, onChange }) {
  const onClick = (value) => {
    const currentValues = Array.isArray(selected) ? [...selected] : [selected];
    return currentValues.includes(value)
      ? onChange(currentValues.filter((val) => val !== value))
      : onChange([...currentValues, value]);
  };

  const isChecked = (value) => {
    const currentValues = Array.isArray(selected) ? [...selected] : [selected];
    return currentValues.find((val) => val === value);
  };

  return (
    <StyledCheckBoxGroup>
      {options?.map(({ value, label }) => (
        <CheckBox
          key={value}
          onClick={() => onClick(label)}
          checked={isChecked(label)}
        >
          {label}
        </CheckBox>
      ))}
    </StyledCheckBoxGroup>
  );
}

const StyledCheckBoxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  height: 26px;
  font-size: 0.9rem; // 0.875rem;
  margin: 0.25rem;
  padding: 0.25rem 0.375rem;
  border-radius: 0.5rem;
  color: ${({ checked }) => (checked ? "white" : "inherit")};
  background: ${({ checked }) =>
    checked ? "rgba(var(--color-blue-rgb), 0.75)" : "white"};
  border: ${({ checked }) =>
    checked ? "1px solid var(--color-blue)" : "1px solid lightgray"};

  //-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease, color 200ms ease, border 200ms ease;
`;
