import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function ScrollTop() {
  const { pathname, search } = useLocation();
  const historyIdx = useRef(0);

  useEffect(() => {
    const currentHistoryIdx = window.history.state.idx;
    if (currentHistoryIdx > historyIdx.current) {
      historyIdx.current = currentHistoryIdx;
      console.log("scroll top::  SCROLL-TOP");
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);
  return null;
}

/*
const ScrollTop = () => {
  // const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const historyIdx = useRef(0);

  useEffect(() => {
    // console.log("scroll top:: useEffect: path?search: ", pathname, search);
    // console.log(
    //   "scroll top:: window.history.state.idx: ",
    //   window.history.state.idx,
    //   window.history.state.key
    // );
    const currentHistoryIdx = window.history.state.idx;
    // console.log(
    //   "scroll top:: currentHistoryIdx ?>? historyIdx.current: ",
    //   currentHistoryIdx,
    //   historyIdx.current
    // );
    if (currentHistoryIdx > historyIdx.current) {
      historyIdx.current = currentHistoryIdx;
      console.log("scroll top::  SCROLL-TOP");
      window.scrollTo(0, 0);
      // document.body.scrollTop = 0;
    }
  }, [pathname, search]);
  return null;
};
*/

const SSSScrollTop = () => {
  // const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const historyIdx = useRef(0);

  useEffect(() => {
    console.log("scroll top:: useEffect: path?search: ", pathname, search);
    // if (!pathname.includes("/movie/")) {
    //   console.log("inside:: !pathname.includes(/movie/");
    //   window.scrollTo(0, 0);
    // }
    // console.dir("window: ", window.history);

    // window.history.listen((h) => {
    //   console.log("h: ", h);
    // });

    // console.log("window.history: ", window.history);
    // console.log(
    //   "window.history.state.idx: ",
    //   window.history.state.idx,
    //   window.history.state.key
    // );
    const currentHistoryIdx = window.history.state.idx;
    if (currentHistoryIdx > historyIdx.current) {
      historyIdx.current = currentHistoryIdx;
      console.log("scroll top::  SCROLL-TOP");
      window.scrollTo(0, 0);
      // document.body.scrollTop = 0;
      // alert("scroll top");
    }

    // window.onpopstate = (e) => {
    // console.group("ScrollTop");
    // console.log("scroll top::onpopstate", e);
    // console.log("scroll top::pathname: ", pathname);
    // console.log("pathname: ", typeof pathname);
    // if (e.type !== "popstate") {
    // if (!pathname.includes("/movie/")) {
    // if (pathname.includes("/movie/")) {
    // console.log("scroll top:: GO-UP pathname.includes(/movie/)");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    // }
    // console.groupEnd();
    // };

    // window.scrollTo(0, 0);
    // if (!pathname.includes("/movie/")) {
    //   if (pathname.includes("/movie/")) {
    //   console.log("scroll top:: GO-UP ?pathname.includes(/movie/)");
    //   window.scrollTo(0, 0);
    // }

    // document.body.getElementsByTagName("main").scrollTop = 0;
    // console.log("window.onpopstate", window.onpopstate);
    // console.log("action: ", action);
    // console.dir("navigate: ", navigate.state);
    // console.dir(navigate);
    // window.scrollTo(0, 0);
  }, [pathname, search]);
  return null;
};
