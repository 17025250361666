// 'Action', 'Adventure', 'Animation', 'Biography', 'Comedy',
// 'Crime', 'Documentary', 'Drama', 'Family', 'Fantasy',
// 'History', 'Horror', 'Music', 'Musical', 'Mystery',
// 'News', 'Romance', 'Sci-Fi', 'Sport', 'Thriller',
// 'War', 'Western'

export const genreOptions = [
  { value: "action", label: "Action" },
  { value: "adventure", label: "Adventure" },
  { value: "animation", label: "Animation" },
  { value: "biography", label: "Biography" },
  { value: "comedy", label: "Comedy" },

  { value: "crime", label: "Crime" },
  { value: "documentary", label: "Documentary" },
  { value: "drama", label: "Drama" },
  { value: "family", label: "Family" },
  { value: "fantasy", label: "Fantasy" },

  { value: "history", label: "History" },
  { value: "horror", label: "Horror" },
  { value: "music", label: "Music" },
  { value: "musical", label: "Musical" },
  { value: "mystery", label: "Mystery" },

  { value: "news", label: "News" },
  { value: "romance", label: "Romance" },
  { value: "sci-fi", label: "Sci-Fi" },
  { value: "sport", label: "Sport" },
  { value: "thriller", label: "Thriller" },

  { value: "war", label: "War" },
  { value: "western", label: "Western" },
];

/*
export const genreOptions = [
  "action",
  "adventure",
  "animation",
  "biography",
  "comedy",

  "crime",
  "documentary",
  "drama",
  "family",
  "fantasy",

  "history",
  "horror",
  "music",
  "musical",
  "mystery",

  "news",
  "romance",
  "sci-fi",
  "sport",
  "thriller",

  "war",
  "western",
];
*/

/*
export const genreOptions = [
  { value: "Action", label: "Action" },
  { value: "Adventure", label: "Adventure" },
  { value: "Animation", label: "Animation" },
  { value: "Biography", label: "Biography" },
  { value: "Comedy", label: "Comedy" },

  { value: "Crime", label: "Crime" },
  { value: "Documentary", label: "Documentary" },
  { value: "Drama", label: "Drama" },
  { value: "Family", label: "Family" },
  { value: "Fantasy", label: "Fantasy" },

  { value: "History", label: "History" },
  { value: "Horror", label: "Horror" },
  { value: "Music", label: "Music" },
  { value: "Musical", label: "Musical" },
  { value: "Mystery", label: "Mystery" },

  { value: "News", label: "News" },
  { value: "Romance", label: "Romance" },
  { value: "Sci-Fi", label: "Sci-Fi" },
  { value: "Sport", label: "Sport" },
  { value: "Thriller", label: "Thriller" },

  { value: "War", label: "War" },
  { value: "Western", label: "Western" },
];
 */
