import React from "react";
import styled, { keyframes } from "styled-components/macro";

const fadeIn = keyframes`
   from {
     opacity: 0;
     //transform: scale(0.95);
   }
   to {
     opacity: 1;
     //transform: scale(1.00);
   }
 `;

/*
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.99);
  }
  20% {
    opacity: 0.2;
    transform: scale(1.00);
  }
  40% {
    opacity: 0.4;
    transform: scale(1.01);
  }
  //60% {
  //  opacity: 0.6;
  //  transform: scale(1.02);
  //}
  //80% {
  //  opacity: 0.80;
  //  transform: scale(1.01);
  //}
  100% {
    opacity: 1;
    transform: scale(1.00);
  }
`;
 */

export default function FadeIn({ duration = 300, delay = 0, children }) {
  return (
    <Wrapper duration={duration} delay={delay}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-duration: ${(props) => props.duration}ms;
    animation-delay: ${(props) => props.delay}ms;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
  }
`;

// const FadeIn = ({ duration = 500, delay = 0, children }) => {
//   return (
//     <Wrapper
//       style={{
//         animationDuration: duration + "ms",
//         animationDelay: delay + "ms",
//       }}
//     >
//       {children}
//     </Wrapper>
//   );
// };
//
// const Wrapper = styled.div`
//   @media (prefers-reduced-motion: no-preference) {
//     animation-name: ${fadeIn};
//     animation-fill-mode: backwards;
//     animation-timing-function: ease-in-out;
//   }
// `;
