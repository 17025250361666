import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Toolbar, LoadMoreButton, Loader, FadeIn } from "../../components";
import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { nameMoviesSortOptions } from "../../constants";
import { endpoints } from "../../api";

// TODO: default logo image for companies missing logos
// TODO: remove movies with only 1 movie? make available in search?

export default function ProductionCompanies() {
  useRenderCount("ProductionCompanies");
  document.title = `Production Companies - MMDb`;

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: nameMoviesSortOptions[0],
  });

  const {
    data,
    status,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiPaginated({
    path: endpoints.productionCompany.all,
    queryParams: queryParams,
    // pageSize: 30,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title="Companies"
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={nameMoviesSortOptions}
      />
      <ProductionCompanyList
        prodcomps={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}

function ProductionCompanyList({ prodcomps, isLoading, isError }) {
  return (
    <>
      {isError && <p>Error</p>}
      {isLoading && (
        <Loading>
          <Loader size="6em" />
        </Loading>
      )}
      {prodcomps?.length === 0 && (
        <Loading>
          <div style={{ color: "white", fontSize: "3rem" }}>
            No Production Companies
          </div>
        </Loading>
      )}
      <ListLayout>
        {(prodcomps || []).map((prodcomp) => (
          <FadeIn key={prodcomp.slug} delay={0}>
            <ProductionCompanyListItem
              key={prodcomp.slug}
              prodcomp={prodcomp}
            />
          </FadeIn>
        ))}
      </ListLayout>
    </>
  );
}

const ListLayout = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  justify-content: center;
  align-content: start;
  padding: 0.75rem;
`;

const Loading = styled.div`
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 5rem;
  color: var(--color-blue);
  background: transparent;
`;

function ProductionCompanyListItem({ prodcomp }) {
  const { slug, name, origin_country, logo_url, movie_count } = prodcomp;

  return (
    <StyledProductionCompanyListItem to={`/production-company/${slug}`}>
      <Logo data-src={logo_url} className="lazyload" alt={name} />
      <p>
        {name} - {origin_country}
      </p>
      <MovieCount>
        <span>{movie_count}</span>
        <span>Movies</span>
      </MovieCount>
    </StyledProductionCompanyListItem>
  );
}

const StyledProductionCompanyListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  height: 75px;
  border-radius: 0.25rem;
  background: white;

  p {
    flex: 1;
    margin: 0 0.75rem;
    font-size: 1rem;
    text-transform: capitalize;
  }
`;

const Logo = styled.img`
  display: flex;
  width: 65px;
  height: 65px;
  //border-radius: 50%;
  padding-left: 5px;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1); // TODO: move this should be in a css var
  object-fit: contain;
`;

const MovieCount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.75rem;

  span:nth-child(1) {
    font-size: 1rem;
  }

  span:nth-child(2) {
    font-size: 0.75rem;
  }
`;
