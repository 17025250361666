const ImdbMovieLink = ({ movie, children }) => {
  const url = `https://imdb.com/title/${movie?.imdb_id}`;
  return (
    <ExternalDataProviderLink url={url}>{children}</ExternalDataProviderLink>
  );
};

const TheMovieDbMovieLink = ({ movie, children }) => {
  const url = `https://themoviedb.org/movie/${movie?.tmdb_id}`;
  return (
    <ExternalDataProviderLink url={url}>{children}</ExternalDataProviderLink>
  );
};

const JustWatchMovieLink = ({ movie, children }) => {
  const slug = movie?.slug.replace(`-${movie?.imdb_id}`, "");
  const url = `https://www.justwatch.com/us/movie/${slug}`;
  return (
    <ExternalDataProviderLink url={url}>{children}</ExternalDataProviderLink>
  );
};

const ExternalDataProviderLink = ({ url, children }) => {
  return (
    <a href={url} target={"_blank"} rel={"noopener noreferrer"}>
      {children}
    </a>
  );
};

export { ImdbMovieLink, TheMovieDbMovieLink, JustWatchMovieLink };
