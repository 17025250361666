import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { device } from "../../constants/devices";

import { MovieListItem } from "./MovieListItem";
import { PosterItem } from "./PosterItem";
import { Loader } from "../";
import { useLocalStorage } from "../../hooks";
import { FadeIn } from "../../components";

// dateType: 'year' (default), 'next', 'theatrical', 'digital', 'physical'
export default function MovieList({
  movies,
  isLoading,
  isError,
  dateType = "year",
}) {
  const [view, setView] = useLocalStorage("list-type", "list");

  useEffect(() => {
    console.log("change view");
  }, [view]);

  return (
    <>
      {isError && <p>Error</p>}
      {isLoading && (
        <Loading>
          <Loader size="6em" />
        </Loading>
      )}
      {movies?.length === 0 && (
        <Loading>
          <div style={{ color: "white", fontSize: "3rem" }}>No Movies</div>
        </Loading>
      )}
      {movies && view === "poster" ? (
        <PosterLayout>
          {(movies || []).map((movie) => (
            <FadeIn key={movie.imdb_id} delay={50}>
              <PosterItem key={movie.imdb_id} movie={movie} />
            </FadeIn>
          ))}
        </PosterLayout>
      ) : (
        <ListLayout>
          {(movies || []).map((movie) => (
            // <FadeIn key={movie.imdb_id} delay={index < 24 ? index * 15 : 300}>
            // <FadeIn key={movie.imdb_id} delay={(index % 24) * 10}>
            <FadeIn key={movie.imdb_id} delay={0}>
              <MovieListItem
                key={movie.imdb_id}
                movie={movie}
                dateType={dateType}
              />
            </FadeIn>
          ))}
        </ListLayout>
      )}
    </>
  );
}

const ListLayout = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  justify-content: center;
  align-content: start;
  padding: 0.75rem;
`;

const PosterLayout = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(4, 1fr);
  //justify-content: center;
  //align-content: start;
  padding: 0.75rem;

  @media ${device.min.small} {
    grid-template-columns: repeat(6, 1fr);
  }

  @media ${device.min.desktop} {
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0.5rem;
  }
`;

const Loading = styled.div`
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 5rem;
  color: var(--color-blue);
  background: transparent;
`;
