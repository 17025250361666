import { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { Filters } from "./components";
import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import { Button } from "../../components/Toolbar";
import {
  ageRatingOptions,
  discoverySortOptions,
  genreOptions,
} from "../../constants";
import { endpoints } from "../../api";

// TODO: put the default filters, isDefault(), filterReducer and others in useFilters hook

export const defaultFilters = {
  genres: [],
  age_rating: [],
  rating_min: 0.0,
  rating_max: 10.0,
  votes_min: 0,
  year_min: 1890,
  year_max: new Date().getFullYear() + 5,
};

// const isDefault = (key, filters) => defaultFilters[key] === filters[key];
const isDefault = (key, filters) => {
  if (Array.isArray(filters[key])) {
    return filters[key]?.length === 0;
  } else {
    return defaultFilters[key] === filters[key];
  }
};

const removeDefaults = (queryParams) => {
  // console.log("removeDefaults: queryParams: ", queryParams);
  let qParams = {};
  for (const key in queryParams) {
    // console.log("key: ", key, " - value: ", queryParams[key]);
    if (!isDefault(key, queryParams)) {
      qParams[key] = queryParams[key];
    }
  }
  console.log("removeDefaults: qParams: ", qParams);
  return qParams;
};

const paramLabelsToValues = (paramLabels, options) => {
  if (!paramLabels) return [];
  // console.log("paramLabels", paramLabels);
  // if (!paramLabels || paramLabels?.length === 0) return [];
  // console.log("paramLabels after check", paramLabels);
  const selectedList = Array.isArray(paramLabels)
    ? [...paramLabels]
    : [paramLabels];
  return selectedList.map(
    (label) => options.find((item) => item.label === label)?.value
  );
};

export default function Discover() {
  useRenderCount("Discovery");
  document.title = "Discover Movies - MMDb";

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: discoverySortOptions[0],
    ...defaultFilters,
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.movie.all,
    queryParams: removeDefaults(queryParams),
    requestParams: removeDefaults({
      genres: paramLabelsToValues(queryParams.genres, genreOptions),
      age_rating: paramLabelsToValues(queryParams.age_rating, ageRatingOptions),
    }),
    // requestParams: {
    //   genres: paramLabelsToValues(queryParams.genres, genreOptions),
    //   age_rating: paramLabelsToValues(queryParams.age_rating, ageRatingOptions),
    // },
    pageSize: 30,
  });

  const [showFilters, setShowFilters] = useState(false);
  const toggleShowFilters = () => setShowFilters((showFilters) => !showFilters);

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  const onApplyFilters = (filterState) => {
    console.log("onApplyFilters: newFilterState: ", filterState);
    setShowFilters(false);
    updateQueryParams(filterState);
  };

  return (
    <>
      <Toolbar
        title={"Discover"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={discoverySortOptions}
        sideButton={
          <Button onClick={toggleShowFilters} isOpen={showFilters}>
            {showFilters ? <IoMdClose /> : <FiFilter />}
          </Button>
        }
      >
        <Filters
          show={showFilters}
          filters={queryParams}
          onApply={onApplyFilters}
          onClose={toggleShowFilters}
        />
      </Toolbar>
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
