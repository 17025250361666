import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useRenderCount } from "../../hooks";
import { appPaths } from "../../constants/appPaths";

export default function Logout() {
  useRenderCount("Signout");
  let navigate = useNavigate();
  const { signout } = useAuth();

  useEffect(() => {
    signout.mutate();
    console.log("logged out - going home");
    navigate(appPaths.home);
  }, [signout, navigate]);

  return (
    <>
      <p>Signing out</p>
    </>
  );
}
