import styled from "styled-components/macro";
import { device } from "../../constants/devices";

export const StyledToolbar = styled.section`
  position: sticky;
  z-index: 1; // TODO: wtf?
  min-height: 40px; // 2.5rem = 40px
  top: var(--header-height);
  padding: 0.5rem 0.75rem;
  background-color: white;
  box-shadow: 0 5px 25px 6px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media ${device.min.tablet} {
  }
`;

export const ButtonWrap = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: center;
  //align-items: center;
  margin-left: auto;
  height: 34px;
`;

export const Button = styled.button`
  padding: 0.375rem 0.75rem;
  margin-left: 0.5rem;
  border: 1px solid lightgray;
  //border: 1px solid rgba(var(--color-blue-rgb), 0.25);
  color: ${({ isOpen }) => (isOpen ? "white" : "inheret")};
  background: ${({ isOpen }) => (isOpen ? "var(--color-blue)" : "white")};

  //transition: box-shadow 0.2s ease;

  &:hover {
    color: ${({ isOpen }) => (isOpen ? "white" : "var(--color-blue)")};
  }
`;

////////

const SearchToolBar = styled(StyledToolbar)``;
const UserFavoritesToolBar = styled(StyledToolbar)``;
const UserReleaseDatesToolBar = styled(StyledToolbar)``;
const ListToolBar = styled(StyledToolbar)`
  display: grid;
  grid-template-areas: "listinfo buttons";
  grid-template-columns: auto auto;
  align-items: center;
`;

const DiscoveryToolBar = styled(StyledToolbar)`
  display: grid;
  // grid-row-gap: 12px;  // TODO: use breakpoint for setting grid-gap ??
  grid-template-areas:
    "listinfo buttons"
    "activefiltersbar activefiltersbar"
    "filtermenu filtermenu";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto auto;
  align-items: center;
`;

const ReleaseDatesToolBar = styled(StyledToolbar)`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-template-areas:
    "listinfo buttons"
    "datepager datepager";
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 40px 40px;
  align-items: center;

  @media ${device.min.tablet} {
    grid-template-areas: "listinfo datepager buttons";
    grid-template-columns: 1fr 2fr 1fr;
    //grid-template-rows: 40px;
    grid-row-gap: 0;
  }
`;
