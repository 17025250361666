import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { LoadMoreButton, MovieList, Toolbar } from "../../components";
import { ProviderTypes } from "./components";
import { endpoints } from "../../api";
import {
  useApi,
  useApiPaginated,
  useQueryParams,
  useRenderCount,
} from "../../hooks";
import { baseSortOptions } from "../../constants";

export default function ProviderDetail() {
  useRenderCount("ProviderDetail");
  const { slug } = useParams();
  const {
    data: provider,
    status: providerStatus,
    error: providerError,
  } = useApi({
    path: endpoints.provider.detail(slug),
  });

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: baseSortOptions[0], // TODO: base movie sort options, merge with others that need special sort options
    type: 0, //  default: all
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.provider.movies(slug),
    queryParams: queryParams,
    pageSize: 30,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  const onTypeChange = (value) =>
    updateQueryParams({ ...queryParams, type: value });

  const streamTypes = useMemo(() => {
    if (!provider) return null;
    return provider?.stream_types.length > 1
      ? [0, ...provider?.stream_types]
      : [...provider?.stream_types];
  }, [provider]);

  return (
    <>
      <Toolbar
        title={provider?.name}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={baseSortOptions}
      >
        <ProviderTypes
          types={streamTypes}
          onTypeChange={onTypeChange}
          selected={queryParams.type}
        />
      </Toolbar>
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
