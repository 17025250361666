import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Toolbar, LoadMoreButton, Loader, FadeIn } from "../../components";
import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { nameMoviesSortOptions } from "../../constants";
import { endpoints } from "../../api";

export default function Keywords() {
  useRenderCount("Keywords");
  document.title = `Keywords - MMDb`;

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: nameMoviesSortOptions[0],
  });
  console.log("keyword sort", nameMoviesSortOptions);

  const {
    data,
    status,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiPaginated({
    path: endpoints.keyword.all,
    queryParams: queryParams,
    pageSize: 60,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title="Keywords"
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={nameMoviesSortOptions}
      />
      <KeywordList
        keywords={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}

function KeywordList({ keywords, isLoading, isError }) {
  return (
    <>
      {isError && <p>Error</p>}
      {isLoading && (
        <Loading>
          <Loader size="6em" />
        </Loading>
      )}
      {keywords?.length === 0 && (
        <Loading>
          <div style={{ color: "white", fontSize: "3rem" }}>No Keywords</div>
        </Loading>
      )}
      <ListLayout>
        {(keywords || []).map((keyword) => (
          <FadeIn key={keyword.slug} delay={0}>
            <KeywordListItem key={keyword.slug} keyword={keyword} />
          </FadeIn>
        ))}
      </ListLayout>
    </>
  );
}

const ListLayout = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  justify-content: center;
  align-content: start;
  padding: 0.75rem;
`;

const Loading = styled.div`
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 5rem;
  color: var(--color-blue);
  background: transparent;
`;

function KeywordListItem({ keyword }) {
  const { slug, name, movie_count } = keyword;

  return (
    <StyledKeywordListItem to={`/keyword/${slug}`}>
      <p>{name}</p>
      <MovieCount>
        <span>{movie_count}</span>
        <span>Movies</span>
      </MovieCount>
    </StyledKeywordListItem>
  );
}

const StyledKeywordListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  height: 50px;
  border-radius: 0.25rem;
  background: white;

  p {
    margin: 0 0.75rem;
    //font-size: 1.1rem;
    text-transform: capitalize;
  }
`;

const MovieCount = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;

  span:nth-child(1) {
    font-size: 1rem;
  }

  span:nth-child(2) {
    font-size: 0.75rem;
  }
`;
