import { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import {
  isEqual,
  formatTagDate,
  eachPeriodOfInterval,
  urlToDate,
  dateToUrl,
} from "../../../hooks/dates";
import { useRenderCount } from "../../../hooks";

const useDates = ({ date, period }) => {
  // const [dates, setDates] = useState([]);
  const [dates, setDates] = useState(() => eachPeriodOfInterval(date, period));

  useEffect(() => {
    console.log(`setting dates for: \ndate: ${date} - (period: ${period})`);
    // setDates(eachPeriodOfInterval(urlToDate(date), period));
    setDates(eachPeriodOfInterval(date, period));
  }, [date, period]);

  return { dates };
};

export default function DateTags({ date, onDateChange, period }) {
  useRenderCount("DateTags");
  const [hasCentered, setHasCentered] = useState(false);
  const { dates } = useDates({ date, period });
  // console.log(`dates`);
  // console.log(dates);

  const centerRef = useCallback(
    (node) => {
      if (node != null && !hasCentered) {
        node.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
        setHasCentered(true);
      }
    },
    [hasCentered]
  );

  useEffect(() => {
    setHasCentered(false);
  }, [date]);

  return (
    // <Wrap>
    <HorizontalScroll>
      {dates?.map((tagDate) => {
        const tagDateFormatted = formatTagDate(tagDate, period);
        const isActiveDay = isEqual(urlToDate(date), tagDate);
        return (
          <Tag
            // key={`${tagDateFormatted} ${tagDate.getFullYear()}`}
            key={tagDateFormatted}
            ref={isActiveDay ? centerRef : null}
            onClick={() => onDateChange(dateToUrl(tagDate))}
            active={isActiveDay}
          >
            {tagDateFormatted}
          </Tag>
        );
      })}
    </HorizontalScroll>
    // </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HorizontalScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 0.375rem;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  margin: 0 0.25rem;
  padding: 0.375rem;
  border-radius: 0.75rem;
  text-transform: capitalize;
  color: ${({ active }) => (active ? "white" : "inherit")};
  background: ${({ active }) =>
    active ? "rgba(var(--color-blue-rgb), 0.75)" : "white"};
  border: ${({ active }) =>
    active ? "1px solid var(--color-blue)" : "1px solid lightgray"};
`;
