import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import RatingBar from "./RatingBar";
import {
  MovieListItemActionIcon,
  FavoriteIcon,
  IsFavoriteIcon,
  WatchListIcon,
  IsWatchListIcon,
  RefreshIcon,
  StarRatingIcon,
  IsStarRatingIcon,
  CloseIcon,
} from "../../Icons";

import { motion, AnimatePresence } from "framer-motion";

export default function ActionMenu({ isOpen, toggleMenu, movie }) {
  const { slug, title } = movie;
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const toggleRatingMenu = () =>
    setIsRatingOpen((isRatingOpen) => !isRatingOpen);

  useEffect(() => {
    console.log("toggleRatingMenu");
  }, [isRatingOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledActionMenu
          key="backdrop"
          variants={menuVariants}
          initial="closed"
          animate="open"
        >
          <Title>{title}</Title>
          <CloseButton onClick={toggleMenu}>
            <CloseIcon />
          </CloseButton>

          <ActionItem slug={slug} name={"Favorite"} icon={<FavoriteIcon />} />
          <ActionItem
            onClick={toggleRatingMenu}
            slug={slug}
            name={"Rate"}
            icon={<StarRatingIcon />}
          />
          <RatingBar
            isOpen={isRatingOpen}
            toggleBar={toggleRatingMenu}
            movie={movie}
          />
          <ActionItem slug={slug} name={"Watch"} icon={<WatchListIcon />} />
        </StyledActionMenu>
      )}
    </AnimatePresence>
  );
}

const menuVariants = {
  closed: {
    visibility: "hidden",
    opacity: 0.75,
    width: "0",
    // transform: "scale(0.95)",
  },
  open: {
    visibility: "visible",
    opacity: 1,
    // width: "320px",
    width: "calc(100% - 0.25rem)",
    // transform: "scale(1.00)",
  },
};

const StyledActionMenu = styled(motion.div)`
  border: 1px solid lightgray;
  background: whitesmoke;
  position: absolute;
  //z-index: 10;
  right: 0;
  top: 0;
  display: flex;

  height: calc(100% - 0.25rem);
  justify-content: space-evenly;
  align-items: center;
  margin: 0.125rem;
  transform-origin: right;
  border-radius: 0.25rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`;

const Title = styled.p`
  position: absolute;
  top: 0.25rem;
`;

const ActionItem = ({ slug, name, icon, onClick }) => {
  return (
    <StyledActionItem onClick={onClick}>
      <span>{icon}</span>
      <p>{name}</p>
    </StyledActionItem>
  );
};

const StyledActionItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;

  span {
    font-size: 2rem;
  }

  p {
  }
`;
