import React from "react";
import styled from "styled-components/macro";
import { Loader } from "../Loaders";

export default function ListInfo({ title, count }) {
  return (
    <ListInfoStyled>
      <Title>{title || "Loading..."}</Title>
      <Count>{count ?? <Loader />}</Count>
    </ListInfoStyled>
  );
}

const ListInfoStyled = styled.div`
  grid-area: listinfo;
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 1.375rem;
  margin-right: 0.5rem;
  text-transform: capitalize;
`;

const Count = styled.span`
  //:before {
  //  content: "#";
  //  opacity: 0.85;
  //}
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 28px;
  min-width: 24px;
  //padding: 0.25rem 0.5rem;
  padding: 0.25rem;
  //padding: 0.375rem;
  //border-radius: 2rem;
  border-radius: 0.375rem;
  font-size: 1.1rem;
  //font-weight: 500;
  background: whitesmoke;
`;
