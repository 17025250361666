import { genreOptions } from "./genres";
import { ageRatingOptions, getAgeRatingLabel } from "./ageRatings";
// TODO: clean up constants exports
export { genreOptions, ageRatingOptions, getAgeRatingLabel };

/* Sort options */
export const userFavoritesSortOptions = ["-votes", "-rating", "-year", "title"];

// these dont all make sense, if most movies are not released yet, most wont have ratings, votes
export const userUpcomingReleasesSortOptions = [
  "release",
  "-rating",
  "-votes",
  "-year",
  "title",
]; // next_release

export const favoritesSortOptions = ["-rating", "-votes", "-year", "title"]; // next_release

export const listSortOptions = ["rank", "-rating", "-votes", "-year", "title"];

export const releasesSortOptions = (type) => {
  // type is a release date from 'release' (all 3 releases), 'theatrical', 'digital', 'physical'
  return [`${type}`, `-rating`, `-votes`, `title`];
};

export const releaseDateSortOptions = [`date`, `-rating`, `-votes`, `title`];

export const discoverySortOptions = ["-votes", "-rating", "-year", "title"];
export const searchSortOptions = ["-votes", "-rating", "-year", "title"];

// TODO: change to baseMovieSortOptions
export const baseSortOptions = ["-votes", "-rating", "-year", "title"];

export const keywordSortOptions = ["-movie_count", "name"];

export const nameMoviesSortOptions = ["-movies", "name"];

/*
const TESTsortOptions = {
  title: {
    asc: { display: "title", value: "-movie_title" },
    desc: { display: "title", value: "movie_title" },
  },
  year: {
    asc: { display: "year", value: "-movie_year" },
    desc: { display: "year", value: "movie_year" },
  },
};
 */
