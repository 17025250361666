import React from "react";
import styled from "styled-components/macro";
import { BiDesktop } from "react-icons/bi";
import { Section, JustWatchMovieLink } from "./";

const isEmptyList = (valArr) => Array.isArray(valArr) && !valArr.length;

export default function StreamOptions({ movie }) {
  const { stream_options } = movie;
  // check for any stream option - return null if no options
  if (Object.values(stream_options).every(isEmptyList)) return null;

  const { sub, rent, buy, ads, free } = stream_options;
  const providers_data = [
    { name: "Sub", items: sub },
    { name: "Rent", items: rent },
    { name: "Buy", items: buy },
    { name: "Ads", items: ads },
    { name: "Free", items: free },
  ];

  return (
    <Section title="Streaming" icon={<BiDesktop />}>
      {providers_data?.map(({ name, items }) => {
        return (
          items?.length > 0 && (
            <Wrap key={name}>
              <JustWatchMovieLink movie={movie}>
                <Name>{name}</Name>
              </JustWatchMovieLink>
              <HorizontalScroll>
                {items?.map((item) => {
                  const { order, name, logo_url } = item;
                  return (
                    <ProviderImage
                      key={`${name}-${order}`}
                      data-src={logo_url}
                      className="lazyload"
                      alt={name}
                    />
                  );
                })}
              </HorizontalScroll>
            </Wrap>
          )
        );
      })}
    </Section>
  );
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 1.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  //border: 1px solid rgba(var(--color-blue-rgb), 0.2);
  position: relative;
  //max-width: max-content;
`;

const Name = styled.div`
  font-size: 0.875rem;
  text-align: center;
  top: 70px;
  left: 4px;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  width: 75px;
  color: lightgray;
  position: absolute;
`;

const HorizontalScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ProviderImage = styled.img`
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
  border-radius: 0.75rem;
  //border: 1px solid rgba(255, 255, 255, 0.1);
`;
