import React, { useState, useRef, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { PosterViewIcon, ListViewIcon, UserIcon } from "../../Icons";
import { useAuth, useLocalStorage, useOnClickOutside } from "../../../hooks";
import { Loader } from "../../";
import { userNavPaths } from "../../../constants/userNavPaths";

export default function UserMenu() {
  const location = useLocation();
  // console.log("UserMenu: location: ", location);

  const { user, isFetchingUser } = useAuth();
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const close = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(ref, close);
  const [view, setView] = useLocalStorage("list-type", "list");

  const items = useMemo(() => {
    return user ? userNavPaths.loggedIn : userNavPaths.loggedOut;
  }, [user]);

  return (
    <Wrap ref={ref}>
      <Button onClick={toggleOpen}>
        {user && <span>{user?.username}</span>}
        {isFetchingUser ? <Loader /> : <UserIcon />}
      </Button>
      <Menu isOpen={isOpen}>
        <ListType type={view}>
          <p>View by</p>
          <div>
            <MenuButton
              selected={view === "list"}
              onClick={() => setView("list")}
            >
              <ListViewIcon />
            </MenuButton>
            <MenuButton
              selected={view === "poster"}
              onClick={() => setView("poster")}
            >
              <PosterViewIcon />
            </MenuButton>
          </div>
        </ListType>
        {items.map(({ name, path }) => (
          <MenuLink key={`${name}-${path}`} onClick={close}>
            <Link to={path} state={{ from: location }}>
              {name}
            </Link>
            {/*<Link to={path}>{name}</Link>*/}
          </MenuLink>
        ))}
      </Menu>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;
  border-radius: 0.25rem;
  //border: 1px solid rgba(var(--color-blue-rgb), 0.375);
  //background: rgba(var(--color-blue-rgb), 0.25);
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: var(--color-blue);
    //border: 1px solid var(--color-blue);
  }
`;

const Button = styled.button`
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem 0.75rem;
  //align-items: stretch;
  color: white;
  background: none;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  height: 100%;

  span {
    margin-right: 0.25rem;
  }
`;

const Menu = styled.ul`
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  position: absolute;
  right: 0;
  top: 40px;
  min-width: 125px;
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0;
  border-radius: 0.25rem;
  background: white;
  border: 1px solid lightgray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  transform: ${({ isOpen }) => (isOpen ? "scale(1)" : "scale(0.95)")};
  //transform-origin: 0 0;
  transform-origin: top right;

  //transition: all 250ms cubic-bezier(0, 1, 0.5, 1);
  transition: all 200ms ease;

  //transition: visibility 150ms cubic-bezier(0, 1, 0.5, 1),
  //  opacity 150ms cubic-bezier(0, 1, 0.5, 1);

  li:nth-last-child(1) {
    border-top: 1px solid lightgray;
    //color: red;
  }
`;

const MenuLink = styled.li`
  //padding: 0.25rem 0.75rem;
  padding: 0.5rem 1rem;

  :hover {
    //background: whitesmoke;
    background: rgba(var(--color-blue-rgb), 0.75);
    //color: var(--color-blue);
    //font-weight: bold;

    a {
      //color: var(--color-charcol);
      color: white;
    }
  }

  a {
    //color: var(--color-charcol);

    :hover {
      //color: var(--color-blue);
    }
  }
`;

//----

const MenuButton = styled.button`
  padding: 0.25rem 0.75rem;
  //font-size: 0.9rem;
  border: 1px solid var(--color-blue);
  color: ${({ selected }) => (selected ? "white" : "inherit")};
  background: ${({ selected }) => (selected ? "var(--color-blue)" : "white")};
`;

const ListType = styled.div`
  display: flex;
  flex-direction: column;
  //margin: 1rem 0;
  padding: 0.5rem 1rem;

  p {
    margin-bottom: 0.25rem;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
