import React, { useState, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components/macro";
import { useOnClickOutside } from "../../hooks";
import { SortCloseIcon, SortDownIcon, SortUpIcon } from "../Icons";

// import { motion, AnimatePresence } from "framer-motion";
import { motion, AnimatePresence } from "framer-motion";

// https://csslayout.io/patterns/dropdown/

const itemVariants = {
  closed: {
    visibility: "hidden",
  },
  open: {
    visibility: "visible",
  },
};

const menuVariants = {
  closed: {
    visibility: "hidden",
    // opacity: 0,
    height: "0",
  },
  open: {
    visibility: "visible",
    // opacity: 1,
    height: "auto",
    // transition: { duration: 2, type: "tween" },
  },
};

export default function SortButtonGroup({ selected, options, onSelect }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const close = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(ref, close);

  const onChange = (value) => {
    onSelect(value);
    close();
  };

  const isDesc = (value) => value.startsWith("-");

  const displayValue = (value) => (isDesc(value) ? value.substring(1) : value);

  const toggleSortDirection = () =>
    onChange(isDesc(selected) ? selected.substring(1) : `-${selected}`);

  return (
    <Wrap ref={ref}>
      <Button onClick={toggleOpen}>{displayValue(selected)}</Button>
      <Sep isOpen={isOpen} />
      {isOpen ? (
        <IconButton onClick={toggleOpen}>
          <SortCloseIcon />
        </IconButton>
      ) : (
        <IconButton onClick={toggleSortDirection}>
          {isDesc(selected) ? <SortUpIcon /> : <SortDownIcon />}
        </IconButton>
      )}
      <AnimatePresence>
        {isOpen && (
          <Menu
            key="menu"
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <Title>Sort by</Title>
            {options?.map((value) => (
              <MenuItem
                key={value}
                variants={itemVariants}
                initial="closed"
                animate="open"
                exit="closed"
                onClick={() => onChange(value)}
                isSelected={displayValue(selected) === displayValue(value)}
              >
                {displayValue(value)}
              </MenuItem>
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Wrap>
  );
}

const Sep = styled.span`
  //rotation: 90deg;
  // old bar
  width: 1px;
  margin: 0.25rem 0;
  //background: rgba(var(--color-blue-rgb), 0.375);
  border-left: 1px solid
    ${({ isOpen }) => (isOpen ? "transparent" : "lightgray")};
  height: ${({ isOpen }) => (isOpen ? "0" : "calc(100% - 0.5rem)")};
  transition: height 200ms ease-in-out;
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  //border: 1px solid rgba(var(--color-blue-rgb), 0.375);
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  background: white;
`;

const Button = styled.button`
  padding: 0.25rem 0.75rem;
  //background: none;
  text-transform: capitalize;
  color: ${({ isOpen }) => (isOpen ? "white" : "inherit")};

  :hover {
    //color: var(--color-blue);
    // color: ${({ isOpen }) => (isOpen ? "white" : "var(--color-blue)")};
    //border-color: var(--color-blue);
  }
`;

const fadeIn = keyframes`
   from {
     opacity: 0;
     //transform: scale(0.95);
   }
   to {
     opacity: 1;
     //transform: scale(1.00);
   }
 `;

const IconButton = styled.button`
  padding: 0.25rem 0.5rem;
  color: rgba(var(--color-charcoal-rgb), 0.75);
  //background: none;

  & > svg {
    //color: rgba(var(--color-charcoal-rgb), 0.75);
    animation-name: ${fadeIn};
    animation-duration: 200ms;
    animation-delay: 0ms;
    animation-fill-mode: backwards;
    //animation-timing-function: ease-in-out;
    animation-timing-function: ease-out;
  }

  :hover {
    color: var(--color-blue);
    border-color: var(--color-blue);
  }
`;

const Title = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: grey;
`;

const Menu = styled(motion.ul)`
  position: absolute;
  right: -1px;
  top: 35px; // under button
  min-width: 120px;
  //min-width: max-content;
  width: calc(100% + 2px);

  display: flex;
  flex-direction: column;
  background: white;
  //padding: 0.5rem 0.25rem;
  padding: 0.25rem 0;
  border: 1px solid lightgray;
  //border: 1px solid rgba(var(--color-blue-rgb), 0.375);
  border-radius: 0.25rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  //transition: transform 250ms ease-in-out, opacity 150ms ease-in-out;
  //transform-origin: top;

  //transition: opacity 200ms cubic-bezier(0, 1, 0.5, 1),
  //  transform 200ms cubic-bezier(0, 1, 0.5, 1),
  //  visibility 200ms cubic-bezier(0, 1, 0.5, 1);
`;

const MenuItem = styled(motion.li)`
  //padding: 0.25rem 0.75rem;
  padding: 0.5rem 0.75rem;
  color: ${({ isSelected }) => (isSelected ? "white" : "inherit")};
  background: ${({ isSelected }) =>
    isSelected ? "var(--color-blue)" : "inherit"};
  text-transform: capitalize;

  //border: 1px solid red;
  //opacity: 0.3;

  //border-radius: 0.25rem;
  //margin: 0.125rem 0.25rem;
  //margin: 0.125rem 0;

  :hover {
    //color: ${(p) => (p.isSelected ? "white" : "inherit")};
    //background: ${(p) => (p.isSelected ? "var(--color-blue)" : "whitesmoke")};
    //color: ${(p) => (p.isSelected ? "white" : "white")};
    //background: ${(p) => (p.isSelected ? "var(--color-blue)" : "#6297de")};
    color: ${({ isSelected }) =>
      isSelected ? "white" : "var(--color-charcoal)"};
    background: ${({ isSelected }) =>
      isSelected ? "var(--color-blue)" : "var(--color-blue-light)"};
  }
`;
