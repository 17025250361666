import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { userFavoritesSortOptions } from "../../constants";

export default function Favorites() {
  useRenderCount("Favorites");
  document.title = `My Favorites - MMDb`;

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: userFavoritesSortOptions[0],
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({ path: "/user/favorites/", queryParams: queryParams });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title={"My Favorites"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={userFavoritesSortOptions}
      />
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
