// # Movie.StreamType.choices=[(1, 'Sub'), (2, 'Rent'), (3, 'Buy'), (4, 'Ads'), (5, 'Free')]
// # Movie.StreamType.labels=['Sub', 'Rent', 'Buy', 'Ads', 'Free']
// # Movie.StreamType.values=[1, 2, 3, 4, 5]
// # Movie.StreamType.names=['SUB', 'RENT', 'BUY', 'ADS', 'FREE']

export const providerTypeOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Sub" },
  { value: 2, label: "Rent" },
  { value: 3, label: "Buy" },
  { value: 4, label: "Ads" },
  { value: 5, label: "Free" },
];
