import React from "react";
import styled, { keyframes } from "styled-components/macro";

import { useMutateMovie } from "../../../hooks";
import { RefreshIcon } from "../../Icons";

export default function RefreshButton({ slug }) {
  const { refresh } = useMutateMovie();

  const refreshData = async () => await refresh.mutateAsync({ slug });

  return (
    <Button onClick={refreshData}>
      {refresh.isLoading ? (
        <WrapAndMove>
          <RefreshIcon />
        </WrapAndMove>
      ) : (
        <Wrap>
          <RefreshIcon />
        </Wrap>
      )}
    </Button>
  );
}

const Button = styled.button`
  position: absolute;
  bottom: 0.125rem;
  right: 0.125rem;
  color: var(--color-charcoal-light);
  background: none;
  opacity: 0.75;
  padding: 0.375rem;
  border-radius: 50%;
  overflow: hidden;

  :hover {
    color: white;
    background: rgba(var(--color-blue-rgb), 0.75);
  }
`;

const Wrap = styled.span`
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

const spinLoader = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const WrapAndMove = styled(Wrap)`
  visibility: visible;
  animation: ${spinLoader} 350ms linear infinite;
`;
