import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { Link } from "react-router-dom";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: calc(100vh - var(--header-height));
  //overflow: hidden;
  //height: 100vh;
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //height: calc(100vh - var(--header-height) - 20rem);
  width: calc(100vw - 2rem);
  //min-width: 400px;
  max-width: 450px;
  padding: 1.75rem 1.25rem;
  margin: 1.5rem 1rem 2rem;
  border-radius: 0.5rem;
  //background: var(--color-blue);
  background: white;
  border: 2px solid var(--color-blue);
  //box-shadow: 0 0 6px 6px rgba(var(--color-blue-rgb), 0.2);

  //border: 1px solid red;

  @media ${device.min.tablet} {
    margin-top: 4rem;
  }
`;

export const Header = styled.h2`
  font-size: 1.75rem;
  line-height: 1.75rem;
  //font-weight: 800;
  //text-align: center;

  //margin: 0.5rem 0;
  color: var(--color-blue);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0;
  //border: 1px solid red;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  margin: 0 0 1rem;
`;

export const Label = styled.label`
  font-weight: 600;
  //margin-bottom: 0.5rem;
  margin: 0.5rem 0;
  color: var(--color-charcoal-light);
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 100%;
  //border: 1px solid lightgray;
  height: 44px;

  :focus-within {
    border: 1px solid var(--color-blue);
    //outline: 1px solid orange;
  }

  border: 1px solid ${({ hasError }) => (hasError ? "red" : "lightgray")};
`;

export const IconWrap = styled.span`
  align-self: center;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  opacity: 0.75;
`;

export const Input = styled.input`
  //margin-bottom: 1rem;
  line-height: 1.5rem;
  //width: 100%;
  flex: 1 1 auto;

  padding: 0.75rem 0;
  //border: 1px solid red;
  border: none;
`;

export const Error = styled.p`
  color: red;
  margin: 0.25rem 0 0;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  //margin: 0.5rem 0;
  margin: 0 0 1rem;
`;

export const Checkbox = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 1rem;
  margin-right: 0.5rem;
  appearance: initial;
  border: 2px solid rgba(var(--color-charcoal-rgb), 0.5);
  outline: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;

  :checked {
    //background: rgba(var(--color-blue-rgb), 0.9);
    //border: 1px solid lightgray;
    background: white;

    //border: 1px solid var(--color-charcoal);
    //text-align: center;
    //color: rgba(var(--color-blue-rgb), 0.9);
    color: var(--color-charcoal);

    :focus {
      border: 2px solid rgba(var(--color-blue-rgb), 0.5);
    }

    ::before {
      content: "\u2713"; // checkmark (regular weight)
    }
  }
`;

export const AuthLink = styled(Link)`
  margin-left: auto;
`;

export const SubmitButton = styled.button`
  padding: 0.75rem 1rem;
  color: white;
  background: var(--color-blue);
  font-weight: 700;

  //:disabled {
  //color: var(--color-blue);
  //background: white;
  //background: rgba(var(--color-blue-rgb), 0.75);
  //background: lightgray;
  //border: 1px solid var(--color-blue);
  //}
`;

export const Detail = styled.p`
  font-size: 1rem;
  //font-weight: 500;
  text-align: center;
  //margin: 0.5rem 0;
  color: rgba(var(--color-charcoal-rgb), 0.75);
`;
