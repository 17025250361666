import { useEffect } from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

import { Portal } from "../../../components";
import { RiCloseLine } from "react-icons/ri";
import { useLockBodyScroll } from "../../../hooks";

import { motion, AnimatePresence } from "framer-motion";

const variants = {
  closed: {
    visibility: "hidden",
    opacity: 0,
    background: "rgba(0, 0, 0, 0.0)",
  },
  open: {
    visibility: "visible",
    background: "rgba(0, 0, 0, 0.5)",
    opacity: 1,
  },
};

const modalVariants = {
  closed: {
    visibility: "hidden",
    opacity: 0,
    transform: "scale(0.95)",
  },
  open: {
    visibility: "visible",
    opacity: 1,
    transform: "scale(1.00)",
  },
};

export default function Modal({
  title,
  isOpen,
  onClose,
  renderBottom,
  children,
}) {
  // does not seem to work on mobile (safari)
  const [setIsLocked] = useLockBodyScroll(isOpen);

  useEffect(() => {
    setIsLocked(isOpen);
  }, [isOpen, setIsLocked]);

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <ModalBackdrop
            key="backdrop"
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <ModalWrap
              key="wrap"
              variants={modalVariants}
              inital="closed"
              animate="open"
              exit="closed"
            >
              <TopBar>
                <h4>{title}</h4>
                <button onClick={onClose}>
                  <RiCloseLine />
                </button>
              </TopBar>
              <Body>{children}</Body>
              <BottomBar>{renderBottom}</BottomBar>
            </ModalWrap>
          </ModalBackdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
}

const ModalBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.min.tablet} {
    display: none;
  }
`;

const ModalWrap = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  max-height: 100%;
  width: 100%;
  margin: 3rem 0.25rem 1rem;
  border-radius: 0.5rem;
  overflow-y: hidden;
  background: white;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid lightgray;

  & > h4 {
    font-size: 1.25rem;
  }

  & > button {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: none;
    //-webkit-tap-highlight-color: transparent;
  }
`;

const Body = styled.div`
  overflow: scroll;
`;

const BottomBar = styled.div``;
