import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { searchSortOptions } from "../../constants";
import { endpoints } from "../../api";

export default function Search() {
  useRenderCount("Search");
  document.title = `Search - MMDb`;

  const [queryParams, updateQueryParams] = useQueryParams({
    search: "",
    sort: searchSortOptions[0],
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.movie.all,
    queryParams,
    enabled: !!queryParams.search,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title={"Search"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={searchSortOptions}
      >
        <div>Results for {queryParams.search}</div>
      </Toolbar>
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
