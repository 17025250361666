import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

export default function Basics({ movie }) {
  const {
    detail_poster_url,
    title,
    year,
    runtime,
    age_rating_display,
    genres,
  } = movie;

  return (
    <BasicsWrap>
      <Title>{title}</Title>
      <Poster src={detail_poster_url} />
      <Details>
        <Tag>{year || "-"}</Tag>
        <Tag>{runtime || "0"}m</Tag>
        <Tag>{age_rating_display}</Tag>
      </Details>
      <Genres>
        {genres?.map((genre) => (
          <Tag key={`genre-${genre}`}>{genre}</Tag>
        ))}
      </Genres>
    </BasicsWrap>
  );
}

const BasicsWrap = styled.div`
  display: grid;
  grid-template-areas:
    "title title title"
    "poster details genres";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr 1fr;
  //grid-row-gap: 1rem;
  //grid-column-gap: 0.5rem;

  @media ${device.min.small} {
    grid-template-areas:
      "poster ."
      "poster title"
      "poster details"
      "poster genres";
    grid-template-rows: 1fr 1fr auto auto;
    grid-template-columns: auto 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    transition: all 0.5s;
  }
`;

const Title = styled.h1`
  grid-area: title;
  font-size: 1.75rem;
  text-align: center;
  justify-self: center;
  align-self: center;
  margin-bottom: 1rem;
  color: white;

  @media ${device.min.small} {
    font-size: 2rem;
    text-align: inherit;
    justify-self: self-start;
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Poster = styled.img`
  grid-area: poster;
  width: 160px;
  height: 240px;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media ${device.min.tablet} {
    width: 250px;
    height: 375px;
  }
`;

const Details = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end; //center; // flex-start  flex-end
  margin-right: 1rem;

  @media ${device.min.small} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; //center; // flex-start  flex-end
    //padding-left: 1rem;
    //margin-top: 1rem;
  }
`;

const Genres = styled.div`
  grid-area: genres;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; //center; // flex-start  flex-end

  @media ${device.min.small} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; // flex-start  flex-end
    //padding-left: 1rem;
    //margin-top: 1rem;
  }
`;

const Tag = styled.span`
  text-align: center;
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0;
  color: whitesmoke;
  border-radius: 0.5rem;
  font-size: 1.1rem;

  border: 1px solid rgba(255, 255, 255, 0.1);
  //background: rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.25);
  font-weight: 500;

  @media ${device.min.small} {
    margin: 0 0.5rem;
  }
`;
