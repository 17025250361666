import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { DropdownNav } from "./";
import {
  otherLinks,
  listLinks,
  discoveryLinks,
  releaseDateLinks,
} from "./navRoutes";

export default function HeaderNav() {
  return (
    <Nav>
      <DropdownNav
        title="Lists"
        leftItems={listLinks.trakt}
        rightItems={listLinks.tmdb}
      />
      <DropdownNav
        title="Releases"
        leftItems={releaseDateLinks}
        rightItems={[]}
        // rightItems={releaseDateLinks.month}
      />
      <DropdownNav
        title="Discover"
        leftItems={discoveryLinks.genres}
        rightItems={discoveryLinks.timePeriod}
      />
      <>
        {otherLinks.map(({ name, path }) => {
          return (
            <NavButton key={name}>
              <Link to={path} key={path}>
                {name}
              </Link>
            </NavButton>
          );
        })}
      </>
    </Nav>
  );
}

const Nav = styled.nav`
  display: none;
  margin-left: 1.5rem;
  margin-right: auto;

  @media ${device.min.small} {
    display: flex;
    font-size: 0.9rem;
    margin-left: 1rem;
  }
`;

const NavButton = styled.div`
  //font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  //border: none;
  border-radius: 0.25rem;

  padding: 0.5rem 0.75rem;
  color: white;
  background: none;

  transition: background-color 200ms ease;
  &:hover {
    background-color: var(--color-blue);
  }
  a {
    &:hover {
      color: white;
    }
  }
`;
