import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";
import { appPaths } from "../constants/appPaths";

// A wrapper for <Route> that redirects to the login screen if user is not yet authenticated.
export default function PrivateRoute({ children }) {
  const location = useLocation();
  console.log("PrivateRoute: location: ", location);
  const { user, isLoadingUser, isFetchingUser } = useAuth();

  if (isLoadingUser || isFetchingUser) return null; // is checking getUser()
  if (!user)
    return <Navigate to={appPaths.login} replace state={{ from: location }} />;

  return children;
}

// export default function PrivateRoute({ children, ...rest }) {
//   const location = useLocation();
//   console.log("PrivateRoute: location: ", location);
//   const { user, isLoadingUser, isFetchingUser } = useAuth();
//   // console.log(`PrivateRoute: user: `, user);
//
//   if (isLoadingUser || isFetchingUser) return null; // is checking getUser()
//   if (!user) {
//     // TODO: do i need to replace here?
//     /**
//      * Navigate:  home
//      *    -> movie-detail
//      *    -> login (sends back to movie-detail)
//      *    -> back (only goes back to movie-detail instead of home)
//      *    back should go home - movie-detail renders twice (being added twice to location/history)
//      */
//     return <Navigate to={appPaths.login} replace state={{ from: location }} />;
//     // return <Navigate to={appPaths.login} state={{ from: location }} />;
//   }
//
//   return <Route {...rest}>{children}</Route>;
// }
