import { useMemo } from "react";
import { formatRelease } from "../../../hooks/dates";
import { DigitalIcon, PhysicalIcon, TheatricalIcon } from "../../Icons";

const ReleaseIcons = ({ name }) => {
  switch (name) {
    case "theatrical":
      return <TheatricalIcon />;
    case "digital":
      return <DigitalIcon />;
    case "physical":
      return <PhysicalIcon />;
    default:
      return null;
  }
};

export default function ReleaseDate({ movie, dateType }) {
  const {
    year,
    theatrical_release,
    digital_release,
    physical_release,
    release = null,
  } = movie;

  const date = useMemo(() => {
    switch (dateType) {
      case "release":
        return formatRelease(release);
      case "theatrical":
        return formatRelease(theatrical_release);
      case "digital":
        return formatRelease(digital_release);
      case "physical":
        return formatRelease(physical_release);
      default:
        return year;
    }
  }, [
    dateType,
    release,
    theatrical_release,
    digital_release,
    physical_release,
    year,
  ]);

  const icons = useMemo(() => {
    if (!release) return [];
    console.log("release", release);
    const icons = [];
    if (release === theatrical_release) icons.push("theatrical");
    if (release === digital_release) icons.push("digital");
    if (release === physical_release) icons.push("physical");
    return icons;
  }, [release, theatrical_release, digital_release, physical_release]);

  return (
    <>
      {icons.map((name) => (
        <ReleaseIcons key={name} name={name} />
      ))}
      {date || "-"}
    </>
  );
}
