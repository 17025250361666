import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

import { FaCloudDownloadAlt, FaTicketAlt } from "react-icons/fa";
import { IoMdDisc } from "react-icons/io";
import { FiCalendar } from "react-icons/fi";
import { Section } from "./";
import { formatDetailRelease } from "../../../hooks/dates";

export default function Releases({ movie }) {
  const { theatrical_release, digital_release, physical_release } = movie;

  return (
    <WrapOnLarge>
      <Section title="Releases" icon={<FiCalendar />}>
        {theatrical_release && (
          <Wrap>
            <Icon>{<FaTicketAlt />}</Icon>
            <Type>Theatrical</Type>
            <Date>{formatDetailRelease(theatrical_release)}</Date>
            {/*<Date>{moment(theatrical_release).format("MMM Do, YYYY")}</Date>*/}
          </Wrap>
        )}
        {digital_release && (
          <Wrap>
            <Icon>{<FaCloudDownloadAlt />}</Icon>
            <Type>Digital</Type>
            <Date>{formatDetailRelease(digital_release)}</Date>
            {/*<Date>{moment(digital_release).format("MMM Do, YYYY")}</Date>*/}
          </Wrap>
        )}
        {physical_release && (
          <Wrap>
            <Icon>{<IoMdDisc />}</Icon>
            <Type>Physical</Type>
            <Date>{formatDetailRelease(physical_release)}</Date>
            {/*<Date>{moment(physical_release).format("MMM Do, YYYY")}</Date>*/}
          </Wrap>
        )}
      </Section>
    </WrapOnLarge>
  );
}

const WrapOnLarge = styled.div`
  display: flex;
  width: 100%;

  @media ${device.min.tablet} {
    width: 50%;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 1rem 0.75rem;
  align-items: center;
  justify-content: space-between;

  width: 300px;
  max-width: 400px;
  //width: 135px;
  padding: 0.25rem 0.5rem;
  //margin-right: 1rem;

  @media ${device.min.small} {
    justify-content: flex-start;
    margin: 0 0 0.75rem;
  }
`;
const Icon = styled.span`
  font-size: 1.25rem;
  color: var(--color-blue);
  display: flex;
  align-items: center;
`;

const Type = styled.span`
  margin-left: 0.5rem;
  margin-right: auto;
  color: lightgray;
`;

const Date = styled.span`
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
`;
