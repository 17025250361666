import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { BiBookAlt } from "react-icons/bi";
import { Section } from "./";
import { Link } from "react-router-dom";

export default function Keywords({ movie }) {
  const { keywords } = movie;
  return (
    keywords && (
      <Section title="Keywords" icon={<BiBookAlt />}>
        {keywords?.length > 0 && (
          <KeywordWrap>
            {keywords?.map(({ slug, name, total_movies }) => (
              <Keyword key={`pc-${slug}`}>
                <Link to={`/keyword/${slug}`}>
                  {name} | {total_movies}
                </Link>
              </Keyword>
            ))}
          </KeywordWrap>
        )}
      </Section>
    )
  );
}

const KeywordWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Keyword = styled.button`
  color: whitesmoke;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(var(--color-blue-rgb), 0.375);
  padding: 0.5rem 0.75rem;
  margin: 0.5rem 0.5rem 0 0;
  text-transform: capitalize;
  font-size: 0.875rem;

  :hover {
    color: white;
    background: rgba(var(--color-blue-rgb), 0.95);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`;
