import {
  addWeeks,
  subWeeks,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  eachWeekOfInterval,
  eachMonthOfInterval,
  isEqual,
  isSameDay,
  format,
  formatISO,
  parseISO,
  isDate,
  toDate,
  isSameMonth,
} from "date-fns";

// const formatDate = (date) => moment(date).format("YYYY-MM-DD");

const isWeek = (period) => period === "week";
const isMonth = (period) => period === "month";

const isSamePeriodDay = (date1, date2) =>
  isSameDay(makeDate(date1), makeDate(date2));

const makeDate = (date) => (isDate(date) ? date : parseISO(date));
// const makeDate = (date) =>
//   console.log("date", date) && isDate(date) ? date : parseISO(date);

const formatDetailRelease = (date) => format(makeDate(date), "MMM do, yyyy");

const formatRelease = (date) => format(makeDate(date), "MMM dd");

// const startOfPeriod = (date, period) =>
//   isWeek(period) ? startOfWeek(makeDate(date)) : startOfMonth(makeDate(date));

const startOfPeriod = (date, period) => {
  const dateObj = makeDate(date);
  const start = isWeek(period) ? startOfWeek(dateObj) : startOfMonth(dateObj);
  return dateToUrl(start);
};

// const endOfPeriod = (date, period) =>
//   isWeek(period) ? endOfWeek(makeDate(date)) : endOfMonth(makeDate(date));

const endOfPeriod = (date, period) => {
  const dateObj = makeDate(date);
  const end = isWeek(period) ? endOfWeek(dateObj) : endOfMonth(dateObj);
  return dateToUrl(end);
};

const eachPeriodOfInterval = (date, period, count = 1) => {
  const dateObj = makeDate(date);
  // console.log(`count: ${count}`);
  const start = isWeek(period)
    ? subWeeks(dateObj, count)
    : subMonths(dateObj, count);
  const end = isWeek(period)
    ? addWeeks(dateObj, count)
    : addMonths(dateObj, count);

  return isWeek(period) // Array of Date Objects
    ? eachWeekOfInterval({ start, end })
    : eachMonthOfInterval({ start, end });
};

const prevPeriod = (date, period) =>
  isWeek(period) ? subWeeks(makeDate(date), 1) : subMonths(makeDate(date), 1);

const nextPeriod = (date, period) =>
  isWeek(period) ? addWeeks(makeDate(date), 1) : addMonths(makeDate(date), 1);

/* Formatting */
// const formatTagDate = (date, period) =>
//   isWeek(period) ? format(date, "MMM dd") : format(date, "MMM ''yy");
// const formatTagDate = (date, period) =>
//   isWeek(period)
//     ? format(makeDate(date), "MMMM dd")
//     : format(makeDate(date), "MMMM yyyy");
const formatTagDate = (date, period) => {
  if (isWeek(period)) {
    const startOfWeek = makeDate(date);
    const endOfWeek = makeDate(endOfPeriod(date, period));
    if (isSameMonth(startOfWeek, endOfWeek)) {
      const startWeek = format(startOfWeek, "MMMM do");
      const endWeek = format(endOfWeek, "do");
      return `${startWeek} - ${endWeek}`;
    }

    const startWeek = format(startOfWeek, "MMM do");
    const endWeek = format(endOfWeek, "MMM do");
    return `${startWeek} - ${endWeek}`;
  } else {
    return format(makeDate(date), "MMMM yyyy");
  }
};

// Date -> return '2021-03-22'
const dateToUrl = (date) => formatISO(date, { representation: "date" });

// '2021-03-22' -> return Date obj
const urlToDate = (date) => parseISO(date);

export {
  isWeek,
  isMonth,
  isEqual,
  isSamePeriodDay,
  startOfPeriod,
  endOfPeriod,
  eachPeriodOfInterval,
  prevPeriod,
  nextPeriod,
  formatTagDate,
  dateToUrl,
  urlToDate,
  formatRelease,
  formatDetailRelease,
};

/* Formatting - format(date, 'format here')
2021 - 'yyyy'
21 - 'yy'

Mar - 'MMM'
March - 'MMMM'
03 - 'MM'

9 - 'd'
18 - 'dd'
18th - 'do'
 */
