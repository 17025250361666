export const otherLinks = [
  {
    name: "Providers",
    path: "/provider",
  },
  {
    name: "Keywords",
    path: "/keyword",
  },
  {
    name: "Companies",
    path: "/production-company",
  },
];

export const mobileListLinks = [
  {
    name: "Trending",
    path: "/list/trakt-trending?sort=rank",
  },
  {
    name: "Popular",
    path: "/list/tmdb-popular?sort=rank",
  },
  {
    name: "Top Rated",
    path: "/list/tmdb-top-rated?sort=rank",
  },
  {
    name: "Recommended",
    path: "/list/trakt-recommended?sort=rank",
  },
  // {
  //   name: "Upcoming",
  //   path: "/list/tmdb-upcoming?sort=rank",
  // },

  {
    name: "Collected",
    path: "/list/trakt-collected?sort=rank",
  },
  {
    name: "Watched",
    path: "/list/trakt-watched?sort=rank",
  },
  // {
  //   name: "Popular",
  //   path: "/list/trakt-popular?sort=rank",
  // },
  {
    name: "Now Playing",
    path: "/list/tmdb-now-playing?sort=rank",
  },
  {
    name: "Played",
    path: "/list/trakt-played?sort=rank",
  },
  {
    name: "Anticipated",
    path: "/list/trakt-anticipated?sort=rank",
  },
  // {
  //   name: "Box Office",
  //   path: "/list/trakt-boxoffice?sort=rank",
  // },
];

export const listLinks = {
  trakt: [
    {
      name: "Trending",
      path: "/list/trakt-trending?sort=rank",
    },
    {
      name: "Collected",
      path: "/list/trakt-collected?sort=rank",
    },
    {
      name: "Watched",
      path: "/list/trakt-watched?sort=rank",
    },

    {
      name: "Popular",
      path: "/list/trakt-popular?sort=rank",
    },
    {
      name: "Recommended",
      path: "/list/trakt-recommended?sort=rank",
    },
    {
      name: "Played",
      path: "/list/trakt-played?sort=rank",
    },
    {
      name: "Anticipated",
      path: "/list/trakt-anticipated?sort=rank",
    },
    {
      name: "Box Office",
      path: "/list/trakt-boxoffice?sort=rank",
    },
  ],
  tmdb: [
    {
      name: "Popular",
      path: "/list/tmdb-popular?sort=rank",
    },
    {
      name: "Top Rated",
      path: "/list/tmdb-top-rated?sort=rank",
    },
    {
      name: "Now Playing",
      path: "/list/tmdb-now-playing?sort=rank",
    },
    {
      name: "Upcoming",
      path: "/list/tmdb-upcoming?sort=rank",
    },
  ],
};

export const releaseDateLinks = [
  // {name: "Release", path: "/release-dates",},
  {
    name: "Theatrical",
    path: "/release-dates/theatrical",
  },
  {
    name: "Digital",
    path: "/release-dates/digital",
  },
  {
    name: "Physical",
    path: "/release-dates/physical",
  },
];

// export const releaseDateLinks = [
//   {
//     name: "Theatrical | Week",
//     path: "/release-dates/theatrical/week",
//   },
//   {
//     name: "Digital | Week",
//     path: "/release-dates/digital/week",
//   },
//   {
//     name: "Physical | Week",
//     path: "/release-dates/physical/week",
//   },
//   {
//     name: "Theatrical / Month",
//     path: "/release-dates/theatrical/month",
//   },
//   {
//     name: "Digital / Month",
//     path: "/release-dates/digital/month",
//   },
//   {
//     name: "Physical / Month",
//     path: "/release-dates/physical/month",
//   },
// ];

export const discoveryLinks = {
  genres: [
    {
      name: "Top Comedies",
      // path: "/discover?sort=-votes&genres=comedy&rating_min=5&votes_min=50000",
      path: "/discover?sort=-votes&genres=Comedy&rating_min=5&votes_min=50000",
    },
    {
      name: "Action/Adventure",
      path:
        // "/discover?sort=-votes&genres=action,adventure&rating_min=5&votes_min=50000",
        "/discover?sort=-votes&genres=Action,Adventure&rating_min=5&votes_min=50000",
    },
    {
      name: "Family Fun",
      // path: "/discover?sort=-votes&genres=animation&age_rating=G,PG",
      path: "/discover?sort=-votes&genres=Animation&age_rating=G,PG",
    },
  ],
  timePeriod: [
    {
      name: "Funnies from the 90's",
      path:
        // "/discover?sort=-votes&genres=comedy&votes_min=50000&year_min=1990&year_max=1999",
        "/discover?sort=-votes&genres=Comedy&votes_min=50000&year_min=1990&year_max=1999",
    },
    {
      name: "Best from the 2000's",
      path: "/discover?sort=-votes&rating_min=6&votes_min=100000&year_min=2000",
    },
    {
      name: "Kids Animation",
      // path: "/discover?sort=-votes&genres=animation&age_rating=G,PG",
      path: "/discover?sort=-votes&genres=Animation&age_rating=G,PG",
    },
  ],
};
