import React from "react";
import { Link } from "react-router-dom";
import lazySizes from "lazysizes";
import styled from "styled-components/macro";
import { BrandIcon } from "../../Icons";

export default function Poster({ poster_url, slug }) {
  return (
    <PosterLink to={`/movie/${slug}`}>
      {poster_url ? (
        <PosterImage data-src={poster_url} className="lazyload" />
      ) : (
        <NoPoster>
          <BrandIcon />
        </NoPoster>
      )}
    </PosterLink>
  );
}

const PosterLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const PosterImage = styled.img`
  //grid-area: poster;
  align-self: center;
  margin-left: 3px;
  width: 70px;
  height: 105px;
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  object-fit: cover;
`;

const NoPoster = styled.div`
  //grid-area: poster;
  align-self: center;
  object-fit: cover; // ?
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 70px;
  height: 105px;
  margin-left: 3px;
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  background: whitesmoke;
  color: var(--color-blue);
  opacity: 0.75;
`;
