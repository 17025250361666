import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { listSortOptions } from "../../constants";
import { endpoints } from "../../api";

export default function Home() {
  useRenderCount("Home");
  const slug = "mmdb-home";
  document.title = `MMDb`;

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: listSortOptions[0],
  });

  const {
    data,
    status,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiPaginated({
    path: endpoints.list.movies(slug),
    queryParams,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title={"Home"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={listSortOptions}
      />
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
