import React from "react";
import styled from "styled-components/macro";
import { ListInfo, SortButtonGroup } from "../../components/Toolbar";
import { device } from "../../constants/devices";

export default function Toolbar({
  title,
  count,
  sortValue,
  onSortSelect,
  sortOptions,
  sideButton = null,
  children,
}) {
  return (
    <Wrap>
      <Top>
        <ListInfo title={title} count={count} />
        <ButtonWrap>
          <SortButtonGroup
            selected={sortValue}
            onSelect={onSortSelect}
            options={sortOptions}
          />
          {sideButton && sideButton}
        </ButtonWrap>
      </Top>
      {children}
    </Wrap>
  );
}

const Wrap = styled.section`
  position: sticky;
  z-index: 1; // TODO: wtf?
  min-height: 40px; // 2.5rem = 40px
  top: var(--header-height);
  padding: 0.5rem 0.75rem;
  background-color: white;
  box-shadow: 0 5px 25px 6px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrap = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: center;
  //align-items: center;
  margin-left: auto;
  height: 34px;
`;

const Button = styled.button`
  padding: 0.375rem 0.75rem;
  margin-left: 0.5rem;
  //border: 1px solid lightgray;
  border: 1px solid rgba(var(--color-blue-rgb), 0.25);
  color: ${({ isOpen }) => (isOpen ? "white" : "inheret")};
  background: ${({ isOpen }) => (isOpen ? "var(--color-blue)" : "white")};
  transition: box-shadow 0.2s ease;

  &:hover {
    color: ${({ isOpen }) => (isOpen ? "white" : "var(--color-blue)")};
  }
`;
