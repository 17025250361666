import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../hooks";
import { Link } from "react-router-dom";

import Poster from "./Poster";
import Details from "./Details";
import FavoriteButton from "./FavoriteButton";
import RefreshButton from "./RefreshButton";

import ActionMenu from "../ActionMenu/ActionMenu";
import { MovieListItemActionIcon } from "../../Icons";

export default function MovieListItem({ movie, dateType }) {
  const { slug, poster_url } = movie;
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((isOpen) => !isOpen);

  useEffect(() => {
    console.log("isOpen", isOpen);
  }, [isOpen]);

  return (
    <StyledMovieListItem>
      <Poster poster_url={poster_url} />
      <Details movie={movie} dateType={dateType} />
      <ButtonWrap>
        <ActionButton onClick={toggleMenu}>
          <MovieListItemActionIcon />
        </ActionButton>
      </ButtonWrap>
      <ActionMenu isOpen={isOpen} toggleMenu={toggleMenu} movie={movie} />

      {/*{user && <FavoriteButton slug={slug} />}*/}
      {/*{user && <RefreshButton slug={slug} />}*/}
    </StyledMovieListItem>
  );
}

const ButtonWrap = styled.div`
  //display: flex;
`;

const ActionButton = styled.button`
  font-size: 1.25rem;
  align-self: start;
  margin: 0.25rem 0.75rem 0 0;
  justify-self: center;
`;

const StyledMovieListItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 0.75rem;
  max-width: 400px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.25rem;
  background: white;
  position: relative; // for action menu positioning
`;
