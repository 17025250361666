import { useMutation, useQueryClient } from "react-query";
import API from "../api/api";

const movieUrls = {
  favorite: (slug) => `/movie/${slug}/favorite/`,
  refresh: (slug) => `/movie/${slug}/refresh/`,
};

export default function useMutateMovie() {
  const queryClient = useQueryClient();

  const favorite = useMutation(
    async ({ isFav, slug }) => {
      const response = await API({
        method: isFav ? "delete" : "post",
        url: movieUrls.favorite(slug),
        data: { slug },
      });
      console.log(`useMutateMovie:favorite: response: `, response);
      return response.data;
    },
    {
      onSuccess: async (data, variables, context) => {
        console.log(`useMutateMovie: movie-slug: ${variables.slug}`);
        // TODO: invalidateQueries 'user' causes AuthProvider to refresh whole app
        // users favorites changing from '/api/user/favorites'
        await queryClient.invalidateQueries(["user"]);
        // await queryClient.invalidateQueries("user"); // will this key match more queries? user/favorites
      },
    }
  );

  const refresh = useMutation(
    async ({ slug }) => {
      const response = await API({
        method: "post",
        url: movieUrls.refresh(slug),
        data: { slug },
        timeout: 5000,
      });
      console.log(`useMutateMovie:refresh: response: `, response);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(["movie", variables.slug]);
      },
    }
  );

  const refreshList = useMutation(
    async ({ slug }) => {
      const response = await API({
        method: "post",
        url: `admin/list/${slug}/refresh/`,
        timeout: 15000,
      });
      console.log(`useMutateMovie:refreshList: response: `, response);
      return response.data;
    },
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(`list/${variables.slug}`);
      },
    }
  );

  return { favorite, refresh, refreshList };
}
