import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { device } from "../../constants/devices";

import { Loader } from "../../components";
import {
  Basics,
  ButtonBar,
  Ratings,
  Overview,
  Info,
  Releases,
  PosterScroll,
  StreamOptions,
  Credits,
  Backdrop,
  Keywords,
} from "./componenets";
import { useApi, useRenderCount } from "../../hooks";
import { endpoints } from "../../api";
import { BiCameraMovie, BiMovie } from "react-icons/bi";

export default function MovieDetail() {
  useRenderCount("MovieDetail");
  const { slug } = useParams();
  const { data: movie, status, error } = useApi({
    path: endpoints.movie.detail(slug),
  });
  const { collection, similar, recommended } = movie || {};

  document.title = movie
    ? `${movie?.title} (${movie?.year}) - MMDb`
    : `Movie Detail - MMDb`;

  // if (status === "loading")
  //   return <div style={{ color: "white" }}>Loading...</div>;

  if (status === "loading") {
    return (
      <Loading>
        <Loader size="6em" />
      </Loading>
    );
  }

  if (status === "error") console.log("error: ", error);
  if (status === "error") return <div style={{ color: "white" }}>error</div>;
  if (!movie) return null; // TODO: loading & error states

  return (
    <Wrap>
      <Backdrop movie={movie} />
      <MovieWrap>
        <Basics movie={movie} />
        <ButtonBar movie={movie} />
        <WrapOnLarge>
          <Ratings movie={movie} />
          <Releases movie={movie} />
        </WrapOnLarge>
        <Overview movie={movie} />
        <Credits movie={movie} />
        <Info movie={movie} />
        <StreamOptions movie={movie} />
        <PosterScroll
          icon={<BiCameraMovie />}
          title={collection?.name || "Collection"}
          movies={collection?.movies}
        />
        <PosterScroll icon={<BiMovie />} title={"Similar"} movies={similar} />
        <PosterScroll
          icon={<BiMovie />}
          title={"Recommended"}
          movies={recommended}
        />
        <Keywords movie={movie} />
        <div style={{ height: "100px" }} />
      </MovieWrap>
    </Wrap>
  );
}

const Loading = styled.div`
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 5rem;
  color: var(--color-blue);
  background: transparent;
`;

const WrapOnLarge = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.min.tablet} {
    flex-direction: row;
  }
`;

const Wrap = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MovieWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 1rem 0 3rem;

  width: 100%;
  max-width: 900px;
  overflow: hidden;

  @media ${device.min.tablet} {
    padding: 0 1.5rem;
    margin: 1.5rem 0 3rem;
  }
`;
