import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { useOnClickOutside } from "../../../hooks";
import { device } from "../../../constants/devices";
import { releaseDateLinks } from "./navRoutes";

export default function DropdownNav({ title, leftItems, rightItems }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const close = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(ref, close);

  return (
    <Wrap ref={ref}>
      <Button onClick={toggleOpen}>{title}</Button>
      <MenuWrap isOpen={isOpen}>
        <Menu>
          {leftItems.map(({ name, path }) => (
            <MenuLink key={path} onClick={close}>
              <Link to={path}>{name}</Link>
            </MenuLink>
          ))}
        </Menu>
        <Menu>
          {rightItems.map(({ name, path }) => (
            <MenuLink key={path} onClick={close}>
              <Link to={path}>{name}</Link>
            </MenuLink>
          ))}
        </Menu>
        {/*<Menu>*/}
        {/*  {leftItems.map(({ name, path }) => (*/}
        {/*    <MenuLink key={path} onClick={close}>*/}
        {/*      <Link to={path}>{name}</Link>*/}
        {/*    </MenuLink>*/}
        {/*  ))}*/}
        {/*</Menu>*/}
        {/*<Menu>*/}
        {/*  {rightItems.map(({ name, path }) => (*/}
        {/*    <MenuLink key={path} onClick={close}>*/}
        {/*      <Link to={path}>{name}</Link>*/}
        {/*    </MenuLink>*/}
        {/*  ))}*/}
        {/*</Menu>*/}
      </MenuWrap>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;
`;

const Button = styled.button`
  padding: 0.5rem 0.75rem;
  color: white;
  background: none;

  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--color-blue);
  }
`;

const MenuWrap = styled.div`
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  position: absolute;
  display: flex;
  flex-direction: row;

  top: 38px;
  left: 0;

  padding: 0.5rem 0;
  background: white;
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  transition: all 300ms cubic-bezier(0, 1, 0.5, 1);
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  //min-width: 150px;
  width: max-content;
  //max-width: 275px;
  padding: 0.25rem;
`;

const MenuLink = styled.li`
  border-radius: 0.25rem;
  & > a {
    display: flex;
    padding: 0.5rem 1rem;

    :hover {
      color: white;
    }
  }

  :hover {
    //color: var(--color-blue);
    color: white;
    background: rgba(var(--color-blue-rgb), 0.75);
    //background: whitesmoke;
  }
`;
