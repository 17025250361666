import React from "react";
import styled from "styled-components/macro";
import { Loader } from "./";

export default function LoadMoreButton({ show, isLoading, onClick }) {
  return (
    <Button onClick={onClick} disabled={isLoading} show={show}>
      {isLoading ? <Loader size={"1.5rem"} /> : `Show More`}
    </Button>
  );
}

const Button = styled.button`
  display: ${({ show }) => (show ? "flex" : "none")};
  height: 45px;
  width: 85%;
  max-width: 400px;
  margin: 0.75rem auto 7.5rem;
  background: white;
`;
