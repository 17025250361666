import React from "react";
import { useParams } from "react-router-dom";
import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import {
  useApiPaginated,
  useMutateMovie,
  useQueryParams,
  useRenderCount,
} from "../../hooks";
import { endpoints } from "../../api";
import { listSortOptions } from "../../constants";
import { Button } from "../../components/Toolbar";
import { IoRefresh } from "react-icons/io5";
import styled, { keyframes } from "styled-components/macro";

const RefreshIcon = ({ isLoading }) => {
  return isLoading ? (
    <WrapAndMove>
      <IoRefresh />
    </WrapAndMove>
  ) : (
    <Wrap>
      <IoRefresh />
    </Wrap>
  );
};

export default function List() {
  useRenderCount("List");
  const { slug } = useParams();
  document.title = `${slug} Movies - MMDb`;
  const { refreshList } = useMutateMovie();

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: listSortOptions[0],
  });

  const {
    data,
    status,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiPaginated({
    path: endpoints.list.movies(slug),
    queryParams,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  const handleRefresh = async () => await refreshList.mutateAsync({ slug });

  return (
    <>
      <Toolbar
        title={slug?.split("-").slice(1).join(" ")}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={listSortOptions}
        sideButton={
          <Button onClick={handleRefresh}>
            <RefreshIcon isLoading={refreshList.isLoading} />
          </Button>
        }
      />
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}

const Wrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
`;

const spinLoader = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const WrapAndMove = styled(Wrap)`
  visibility: visible;
  animation: ${spinLoader} 350ms linear infinite;
`;
