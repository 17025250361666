import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import ReleaseDate from "./ReleaseDate";
import { getAgeRatingLabel } from "../../../constants";

const Votes = ({ votes }) => {
  if (!votes) return null;
  let votes_str = "";
  if (votes >= 10000) {
    votes = (votes / 1000).toFixed(0);
    votes_str = `${votes.toLocaleString()}K`;
  } else {
    votes_str = votes.toLocaleString();
  }
  return <InfoItem>{votes_str}</InfoItem>;
};

const Runtime = ({ runtime }) => {
  // if (!runtime) return null;
  return (
    <InfoItem>
      {runtime || "0"}
      <small>m</small>
    </InfoItem>
  );
};

const Rating = ({ rating }) => {
  return (
    <InfoItem>
      {rating ? Number.parseFloat(rating).toPrecision(2) : "0.0"}
    </InfoItem>
  );
};

const Genres = ({ genres }) => {
  if (!genres) return null;
  return genres?.map((genre) => <InfoItem key={genre}>{genre}</InfoItem>);
};

const AgeRating = ({ ageRating }) => {
  return <InfoItem>{getAgeRatingLabel(ageRating)}</InfoItem>;
};

const Date = ({ movie, dateType }) => {
  return (
    <InfoItem>
      <ReleaseDate movie={movie} dateType={dateType} />
    </InfoItem>
  );
};

export default function Details({ movie, dateType }) {
  const {
    slug,
    title,
    runtime,
    age_rating,
    imdb_rating_avg,
    imdb_rating_count,
    genres,
    is_english,
  } = movie;

  return (
    <InfoWrap to={`/movie/${slug}`}>
      <Title isEng={is_english}>{title}</Title>
      <InfoRow>
        <Date movie={movie} dateType={dateType} />
        <Runtime runtime={runtime} />
        <AgeRating ageRating={age_rating} />
        <Rating rating={imdb_rating_avg} />
        <Votes votes={imdb_rating_count} />
      </InfoRow>
      <InfoRow>
        <Genres genres={genres} />
      </InfoRow>
    </InfoWrap>
  );
}

// TODO: what to show if a movie title is longer than two lines?
//  '...'?  or fade out white  //  line clamp?
const Title = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  max-height: 2.4rem;
  overflow: hidden;
  white-space: normal;
  //margin-right: 0.25rem;
  margin-bottom: 0.75rem;
  text-overflow: ellipsis;

  & > a {
    color: ${({ isEng }) => (isEng ? "inherit" : "red")};
  }
`;

const InfoWrap = styled(Link)`
  //grid-area: infowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 0 1.5rem 0 0; // to give favorite btn spaces
`;

const InfoRow = styled.div`
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 4px;
  //padding-left: 4px;
  color: var(--color-charcoal-light);
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  :last-of-type {
    // need to keep last item from expanding the InfoRow?
    // pushing fav button off MovieListItem
    padding-right: 0;
  }

  & > svg {
    font-size: 1rem;
    margin-right: 0.25rem;
    align-self: center;
  }

  & > small {
    align-self: flex-end;
  }
`;
