import { useReducer, useEffect } from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

import Section from "./Section";
import RangeSlider from "./RangeSlider";
import CheckBoxGroup from "./CheckBoxGroup";

import { defaultFilters } from "../Discover";
import { ageRatingOptions, genreOptions } from "../../../constants";

// TODO: add clear/reset button(s) to set filters to defaults or original state

const filterReducer = (state, action) => {
  switch (action.type) {
    case "SET_AGE_RATING":
      return { ...state, age_rating: action.payload };
    case "SET_GENRES":
      return { ...state, genres: action.payload };
    case "SET_RATINGS":
      return {
        ...state,
        rating_min: action.payload[0],
        rating_max: action.payload[1],
      };
    case "SET_VOTES":
      return { ...state, votes_min: action.payload };
    case "SET_YEARS":
      return {
        ...state,
        year_min: action.payload[0],
        year_max: action.payload[1],
      };
    case "FILTER_RESET":
      return { ...action.payload };
    case "FILTER_DEFAULTS":
      return { ...defaultFilters };
    default:
      throw new Error();
  }
};

export default function FilterMenu({ isOpen, filterState, onApplyFilters }) {
  const [state, dispatch] = useReducer(filterReducer, {
    ...defaultFilters,
    ...filterState,
  });

  const onRatingChange = (values) =>
    dispatch({ type: "SET_RATINGS", payload: values });

  const onMinVotesChange = (value) =>
    dispatch({ type: "SET_VOTES", payload: value });

  const onYearChange = (values) =>
    dispatch({ type: "SET_YEARS", payload: values });

  const onGenreChange = (values) =>
    dispatch({ type: "SET_GENRES", payload: values.sort() });

  const onAgeRatingChange = (values) =>
    dispatch({ type: "SET_AGE_RATING", payload: values.sort() });

  const onReset = (state) => dispatch({ type: "FILTER_RESET", payload: state });

  const onApply = () => onApplyFilters(state);

  useEffect(() => {
    console.log("FilterMenu: state ", state);
  }, [state]);

  useEffect(() => {
    onReset(filterState);
    // onReset({ ...defaultFilters, ...filterState });
  }, [isOpen, filterState]);

  return (
    <Wrap>
      <Group>
        <Section title="Genres">
          <CheckBoxGroup
            options={genreOptions}
            selected={state.genres}
            onChange={onGenreChange}
          />
        </Section>
        <Section title="Age Rating">
          <CheckBoxGroup
            options={ageRatingOptions}
            selected={state.age_rating}
            onChange={onAgeRatingChange}
          />
        </Section>
        {/*
        <Section title="SomeRating">
          <input
            name={"somerating"}
            type={"range"}
            min={0}
            max={100}
            step={1}
            value={state.rating_max}
            onChange={onRatingChange}
            // multiple={true}
            // value={[state.rating_min, state.rating_max]}
          />
        </Section>
        */}
      </Group>
      <Group>
        <Section
          title="Rating"
          value={`${Number.parseFloat(state.rating_min).toPrecision(2)} - 
              ${Number.parseFloat(state.rating_max).toPrecision(2)}`}
        >
          <RangeSlider
            value={[state.rating_min, state.rating_max]}
            onChange={onRatingChange}
            min={defaultFilters.rating_min}
            max={defaultFilters.rating_max}
            step={0.1}
          />
        </Section>
        <Section title="Votes" value={state.votes_min?.toLocaleString()}>
          <RangeSlider
            value={[state.votes_min]}
            onChange={onMinVotesChange}
            min={0}
            max={100000}
            step={1000}
          />
        </Section>
        <Section title="Year" value={`${state.year_min} - ${state.year_max}`}>
          <RangeSlider
            value={[state.year_min, state.year_max]}
            onChange={onYearChange}
            min={defaultFilters.year_min}
            max={defaultFilters.year_max}
            step={1}
          />
        </Section>
        <ActionButtonWrap>
          <Button
            onClick={onApply}
            // disabled={defaultFilters === state}
          >
            Apply
          </Button>
        </ActionButtonWrap>
      </Group>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  //margin: 0.5rem 0;
  margin: 0 0 0.5rem;

  @media ${device.min.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 1px solid lightgray;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 450px;

  @media ${device.min.tablet} {
    width: 50%;
  }
`;

const ActionButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 0.5rem 0 0.5rem;

  //position: sticky;
  //bottom: 0;
`;

const Button = styled.button`
  width: 85%;
  padding: 0.75rem 0.5rem;
  color: white;
  background: var(--color-blue);
  border: 1px solid lightgray;
`;
