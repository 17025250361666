import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

import { useLockBodyScroll, useAuth } from "../../../hooks";
import { MenuIcon, CloseIcon } from "../../Icons";
import { Brand } from "./";
import { Portal } from "../../";
import {
  otherLinks,
  mobileListLinks,
  discoveryLinks,
  releaseDateLinks,
} from "./navRoutes";

import Modal from "../../../pages/Discover/components/Modal";

export default function MobileNav() {
  // const { user } = useAuth();
  const [showNav, setShowNav] = useState(false);
  const toggleNav = () => setShowNav((showNav) => !showNav);

  return (
    <Portal>
      <MobileMenuButton onClick={toggleNav}>
        {showNav ? <CloseIcon /> : <MenuIcon />}
      </MobileMenuButton>

      <Wrap isOpen={showNav}>
        {/*<Modal*/}
        {/*title={""}*/}
        {/*isOpen={showNav}*/}
        {/*onClose={() => setShowNav(false)}*/}
        {/*renderBottom={null}*/}
        {/*>*/}
        <Brand />
        <Section>
          <Link to={"/list/mmdb-home"} onClick={toggleNav}>
            <Header>Lists</Header>
          </Link>
          <Lists>
            {mobileListLinks.map(({ name, path }) => {
              return (
                <Link to={path} onClick={toggleNav} key={path}>
                  {name}
                </Link>
              );
            })}
          </Lists>
        </Section>
        <Section>
          <Link to={"/release-dates"} onClick={toggleNav}>
            <Header>Releases</Header>
          </Link>
          <Lists>
            {releaseDateLinks.map(({ name, path }) => {
              return (
                <Link to={path} onClick={toggleNav} key={path}>
                  {name}
                </Link>
              );
            })}
          </Lists>
        </Section>
        <Section>
          <Link to={"/discover?sort=-votes"} onClick={toggleNav}>
            <Header>Discover</Header>
          </Link>
          <ReleasesWrap>
            <FlexContainer column>
              {discoveryLinks.genres.map(({ name, path }) => {
                return (
                  <Link to={path} onClick={toggleNav} key={path}>
                    {name}
                  </Link>
                );
              })}
            </FlexContainer>
            <FlexContainer column>
              {discoveryLinks.timePeriod.map(({ name, path }) => {
                return (
                  <Link to={path} onClick={toggleNav} key={path}>
                    {name}
                  </Link>
                );
              })}
            </FlexContainer>
          </ReleasesWrap>
        </Section>
        <>
          {otherLinks.map(({ name, path }) => {
            return (
              <Link to={path} onClick={toggleNav} key={path}>
                <Section>
                  <Header>{name}</Header>
                </Section>
              </Link>
            );
          })}
        </>
      </Wrap>
      {/*</Modal>*/}
    </Portal>
  );
}

const Section = styled.div`
  padding: 0 1rem;
  margin: 1.25rem 0;

  a {
    font-size: 1.15rem;
    justify-self: center;
    align-self: center;
    padding: 0.25rem 0;
    color: whitesmoke;
  }
`;

const Header = styled.h2`
  font-size: 1.75rem;
  //font-weight: 600;
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--color-blue);
  opacity: 0.75;
`;

///////////////

const Lists = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  //grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: ${({ short }) => (short ? "1fr 1fr" : "1fr 1fr 1fr")};
`;

const ReleasesWrap = styled.div`
  display: flex;
  justify-content: space-around;

  p {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    color: lightgray;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

const DiscoverWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

////////////////////////

const Wrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  //width: 100vw;
  //height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  border: 2px solid lightgray;
  border-radius: 0.25rem;
  //background: var(--color-charcoal);
  background: linear-gradient(to top, #232526, #2e3032);

  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transform: ${({ isOpen }) => (isOpen ? "scale(1)" : "scale(0.95)")};
  transform-origin: bottom right; // same as 100% 100%;
  z-index: 1010;

  transition: all 200ms ease-in-out;

  padding: 0.5rem;

  @media ${device.min.tablet} {
    display: none;
  }
`;

const MobileMenuButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 1.5rem;
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem;
  color: white;
  background: rgba(var(--color-blue-rgb), 0.95);
  border: 1px solid lightgray;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 1011;

  @media ${device.min.tablet} {
    display: none;
  }
`;
