import React from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

export default function Section({ title, value, children }) {
  return (
    <Wrap>
      <Header>
        <p>{title}</p>
        <span>{value}</span>
      </Header>
      {children}
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  //padding: 1rem 0.5rem;
  padding: 0.75rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  & > p {
    margin-bottom: 0.25rem;
  }
`;
