import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;
      margin: 0;      
    }
    
    :root {
      /* Colors */
      
      //background: #282c35;
      //background: #33425b;
      //--color-charcoal: #353C45;
      //--color-charcoal-light: #353C45DA; // same as charcoal but with alpha (DA=85%)
      //--color-charcoal: #2c3e50;
      //--color-primary-blue: #2C97DE;
      //--color-grey: #EEEEEE;
      --color-white: #FFFFFF;

      // like
      /// --color-charcoal: #1e1e1e;
      //--color-charcoal-light: #353C45;
      /// --color-charcoal-light: #425466;
      //--color-blue: #005195;
      /// --color-blue: #2867bc;
      // end like
      
      // try
      //--color-charcoal: #282c35; // another-med
      //--color-charcoal-rgb: 40,44,53; // another-med
      --color-charcoal: #1f2326; // med
      --color-charcoal-rgb: 12, 14, 25; // med
      
      //--color-charcoal: #0a1016;  // dark
      //--color-charcoal-rgb: 10, 16, 22; // dark
      --color-charcoal-light: #525252;
      ///   --color-blue: #2d74d2;
      --color-blue-rgb: 45, 116, 210;
      //--color-blue: #2C97DE;
      --color-blue-light: #dce9fe;
      //--color-blue: #1460AA;  // contrast ratio (to #FFF & #000 6.4) 
      // end try
      
      //--color-charcoal: #212624;  // // 33, 38, 36
      //--color-charcoal: #0a1016;

      //--color-blue: #54678F;
      --color-blue: #2d74d2;
      //--color-blue: #4078c0;
      
      // LIGHTER GREY
      //--color-charcoal: #373d41;
      //--color-charcoal-rgb: 22, 24, 25;
      
      
      
      //--color-charcoal: #1e1e20;
      //--color-charcoal: #1a1a1a;
      //--color-charcoal: #272d32;
      //--color-charcoal: #2D2A2A;

      //--color-charcoal-light: #a2aab6;
      //--color-charcoal-light: #c4c9cc; // to light
      //--color-charcoal: #424656;
      //--color-charcoal-light: #A6ABBD;
      
      //--color-blue: #284888;
      //--color-blue: #136ad5;
      //--color-blue: #0066a1;
      //--color-blue: #1c79c0;
      //--color-blue: #2C73D1;
      //--color-blue: #0079bf; // trello (to light)
      
      // flat pallet
      // --light-grey: #C8D6E5
      // --med-grey: #8395A7
      // --dark-grey: #576574
      // --black: #212F3E
      // --light-blue: #54A0FF
      // --dark-blue: #2E86DE
      // --purple: #5F27CD
      
      /* Sizes */
      
      //--max-content-width: 1024px;
      --max-content-width: 1200px;
      --header-height: 55px;
      //--header-height: 60px;
      //--toolbar-height: 40px;
      
      // themes light/dark:
      //--color-primary:
      //--color-secondary:
      //--color-primary-blue
      //--color-secondary-blue
    }
        
    body {
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100vw;
      //height: 100vh; // TODO: not sure, messes with scroll?
      font-size: 16px;
      //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      //sans-serif;
      //font-family: 'Roboto', sans-serif; 
      
      font-family: 'Ubuntu', -apple-system-body, sans-serif;
      // from bumbag.style/fonts // font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      
      letter-spacing: 0.5px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      //background: none;
      background: var(--color-charcoal);
      color: var(--color-charcoal);
    }
    
    a {
      text-decoration: none;

      :hover {
        color: var(--color-blue);
      }
    }
    
    button {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 0.25rem;
      background: none;
      
      //text-decoration: none;

      -webkit-tap-highlight-color: transparent;  // keeps iOS from showing grey background when tapping a button

      //:hover {
      //  color: var(--color-blue);
      //}
    }
    
    ul, li {
      list-style: none;
    }

    a,
    input,
    button,
    textarea,
    select {
      font: inherit;
      color: inherit;
    }
    
    input {
      appearance: none;
      border: 1px solid lightgray;
      outline: none;
      height: 35px;
      border-radius: 0.25rem;
      font-size: 1rem;
      //-webkit-appearance: none;
      //-webkit-border-radius: 0;
    }
`;
