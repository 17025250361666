import React from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

export default function Section({ title, icon, children }) {
  return (
    <Wrap>
      <Header>
        {/*<span>*/}
        {/*  <Icon />*/}
        {/*</span>*/}
        <span>{icon}</span>
        <h3>{title}</h3>
      </Header>
      {children}
    </Wrap>
  );
}

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 0.125rem 0.5rem;
  margin: 0.5rem 0 1.5rem;
  color: whitesmoke;
  border-radius: 0.125rem;
  border-left: 0.25rem solid var(--color-blue);

  span {
    display: flex;
    align-items: center;
    color: lightgray;
    margin-right: 0.25rem;
    svg {
    }
  }

  h3 {
    font-weight: 500;
    color: whitesmoke;
  }
`;
