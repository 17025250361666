import { useInfiniteQuery } from "react-query";
import API from "../api/api";

const combinePageResults = (pages) => {
  return pages?.reduce((allPages, page) => [...allPages, ...page.results], []);
};

export default function useApiPaginated({
  path,
  queryParams,
  requestParams = {},
  pageSize = 30,
  enabled = true,
}) {
  const params = { ...queryParams, ...requestParams };
  return useInfiniteQuery(
    [path, params],
    async ({ pageParam = 1 }) => {
      const response = await API({
        method: "get",
        url: path,
        params: { page: pageParam, page_size: pageSize, ...params },
      });
      console.debug(`useApiPaginated (${path}): response: `, response);
      return response.data;
    },
    {
      enabled: enabled,
      getNextPageParam: (lastPage) => lastPage.next_page,
      select: (data) => {
        return {
          count: data?.pages[0]?.count,
          items: combinePageResults(data?.pages),
        };
      },
    }
  );
}

// --- // --- // --- // --- // --- // --- // --- // --- // --- // --- // --- // --- // --- // --- //

/*
function useMovies({ queryParams, requestParams = {}, enabled = true }) {
  // console.log("useMovies: queryParams: ", queryParams);
  // const genres = translateGenreParams(queryParams);
  const age_rating = translateAgeRatingParams(queryParams);
  const params = { ...queryParams, age_rating, ...requestParams };

  return useInfiniteQuery(
    ["movies", params],
    ({ pageParam = 1 }) => getMovies({ params, pageParam }),
    {
      enabled: enabled,
      getNextPageParam: (lastPage) => lastPage.next_page,
      select: (data) => {
        return {
          count: data?.pages[0].count,
          movies: combinePageResults(data?.pages),
        };
      },
    }
  );
}

const getMovies = async ({ params, pageParam }) => {
  // const source = CancelToken.source();
  const response = await API.get(`/movies/`, {
    params: { page: pageParam, page_size: 24, ...params },
    // cancelToken: source.token,
  });
  // response.cancel = () => {
  //   source.cancel("Query was cancelled by React-Query");
  // };
  // printGetsMovieData(params, pageParam, response);
  return response.data;
};

const printGetsMovieData = (params, pageParam, response) => {
  console.debug("useMovies: printGetsMovieData - start");
  console.debug("useMovies:getMovieList: params=", params);
  console.debug("useMovies:getMovieList: pageParam=", pageParam);
  console.debug(`useMovies:getMovieList: response=`, response);
  console.debug("useMovies: printGetsMovieData - end");
};
 */
