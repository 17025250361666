import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { BrandIcon } from "../../Icons";
import { appPaths } from "../../../constants/appPaths";

export default function Brand() {
  return (
    <NavBrand>
      <Link to={appPaths.home}>
        <BrandIcon />
        MMDb
      </Link>
    </NavBrand>
  );
}

const NavBrand = styled.h1`
  font-size: 1.75rem;
  color: var(--color-blue);

  & > a {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 0.125rem;
    }
  }
`;
