import { useMemo, useCallback } from "react";
import { FaRegCalendar, FaRegCheckSquare, FaRegStar } from "react-icons/fa";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { defaultFilters } from "../Discover";

const formatArray = (items) =>
  Array.isArray(items) ? items.join(", ") : items;

const formatRating = (rating_min, rating_max) => {
  // need access to isDefault and both filters & defaultFilters objects
};

// const formatGenres = (genres) => {
//   return Array.isArray(genres) ? genres.join(", ") : genres;
// };

export default function ActiveFilters({ filters }) {
  // console.log("ActiveFilters: filters", filters);
  const {
    genres,
    age_rating,
    rating_min,
    rating_max,
    votes_min,
    year_min,
    year_max,
  } = filters;

  // isDefault(key, filters, defaultFilters)

  const isDefault = useCallback((key) => defaultFilters[key] === filters[key], [
    filters,
  ]);

  const genresString = useMemo(() => formatArray(genres), [genres]);

  const ageRatingString = useMemo(() => formatArray(age_rating), [age_rating]);

  const ratingsString = useMemo(() => {
    const formatRating = (number) => Number.parseFloat(number).toPrecision(2);
    if (isDefault("rating_min") && isDefault("rating_max")) {
      return null;
    } else if (isDefault("rating_max")) {
      return `${formatRating(rating_min)}+`;
    }
    return `${formatRating(rating_min)} - ${formatRating(rating_max)}`;
  }, [rating_min, rating_max, isDefault]);

  const votesString = useMemo(() => {
    if (!votes_min || isDefault("votes_min")) {
      return null;
    }
    return `${votes_min.toLocaleString()}+`;
  }, [votes_min, isDefault]);

  const yearsString = useMemo(() => {
    if (isDefault("year_min") && isDefault("year_max")) {
      return null;
    } else if (isDefault("year_max")) {
      return `${year_min}+`;
    }
    return `${year_min} - ${year_max}`;
  }, [year_min, year_max, isDefault]);

  const noFilters = useMemo(() => {
    return (
      !genresString &&
      !ageRatingString &&
      !ratingsString &&
      !votesString &&
      !yearsString
    );
  }, [genresString, ageRatingString, ratingsString, votesString, yearsString]);

  return (
    <Bar>
      <Title>Filters</Title>
      {noFilters && <Tag>No Filters</Tag>}
      {genresString && <Tag>{genresString}</Tag>}
      {ageRatingString && <Tag>{ageRatingString}</Tag>}
      {ratingsString && (
        <Tag>
          <FaRegStar />
          {ratingsString}
        </Tag>
      )}
      {votesString && (
        <Tag>
          <FaRegCheckSquare />
          {votesString}
        </Tag>
      )}
      {yearsString && (
        <Tag>
          <FaRegCalendar />
          {yearsString}
        </Tag>
      )}
    </Bar>
  );
}

const Bar = styled.div`
  //grid-area: activefiltersbar;
  color: gray;
  margin-top: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

const Title = styled.h3`
  display: none;
  font-size: 1rem;
  font-weight: 400;
  margin-right: auto;

  @media ${device.min.tablet} {
    display: flex;
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.25rem;
  padding: 0.125rem 0.375rem;
  border-radius: 0.375rem;
  font-size: 0.9rem;
  text-transform: capitalize;
  color: var(--color-charcoal);
  background: whitesmoke;

  &:last-of-type {
    margin: 0;
  }

  svg {
    color: var(--color-charcoal-light);
    margin-right: 0.25rem;
  }
`;
