import React from "react";
import styled from "styled-components/macro";
import { Link, useParams } from "react-router-dom";
import { LoadMoreButton, MovieList, Toolbar } from "../../components";
import {
  useApi,
  useApiPaginated,
  useQueryParams,
  useRenderCount,
} from "../../hooks";
import { BrandIcon, StarRatingIcon } from "../../components/Icons";
import { endpoints } from "../../api";
import { baseSortOptions } from "../../constants";

export default function Actor() {
  useRenderCount("Actor");
  const { slug } = useParams();

  const { data: actor, status: actorStatus, error: actorError } = useApi({
    path: endpoints.actor.detail(slug),
  });

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: baseSortOptions[0],
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.actor.movies(slug),
    queryParams: queryParams,
    pageSize: 30,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title={actor?.name || "Actor"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={baseSortOptions}
      />
      <PosterScroll title={"Top Movies"} movies={actor?.top_movies} />
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}

/* BELOW from MovieDetail components/PosterStroll */

const Poster = ({ url }) => {
  return url ? (
    <PosterImage data-src={url} className="lazyload" />
  ) : (
    <NoPoster>
      <BrandIcon />
    </NoPoster>
  );
};

const PosterImage = styled.img`
  //display: flex;
  width: 100px;
  height: 150px;
  //border-radius: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1); // TODO: move this should be in a css var
  object-fit: cover;
`;

const NoPoster = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;

  width: 100px;
  height: 150px;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(var(--color-blue-rgb), 0.5);
  color: var(--color-charcoal);
`;

function PosterScroll({ title, movies }) {
  if (!movies || movies.length === 0) return null;
  return (
    <Section title={title}>
      <HorizontalScroll>
        {movies?.map((movie) => {
          const { slug, title, poster_url, imdb_rating_avg } = movie;
          return (
            <ScrollLink key={slug} to={`/movie/${slug}`}>
              <Poster url={poster_url} />
              <Title>{title}</Title>
              {imdb_rating_avg && (
                <Rating>
                  <StarRatingIcon />
                  <span>{imdb_rating_avg}</span>
                </Rating>
              )}
            </ScrollLink>
          );
        })}
      </HorizontalScroll>
    </Section>
  );
}

const HorizontalScroll = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ScrollLink = styled(Link)`
  //display: flex;
  flex-direction: column;
  margin-right: 0.75rem;
  max-width: 100px;
  position: relative;
`;

const Title = styled.p`
  font-size: 0.75rem;
  overflow: hidden; // want '...'
  line-height: 1rem;
  height: 2rem;
  margin-top: 0.25rem;
  color: lightgray;
`;

const Rating = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;

  //border: 1px solid orange;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 0.75rem;
  svg {
    //font-size: 0.8rem;
    margin-right: 0.125rem;
    color: lightgray;
  }

  span {
  }
`;

function Section({ title, children }) {
  return (
    <Wrap>
      <Header>
        <h3>{title}</h3>
      </Header>
      {children}
    </Wrap>
  );
}

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.75rem 0;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  width: max-content;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 0.125rem 0.5rem;
  margin: 0.5rem 0 1rem;
  color: whitesmoke;
  border-radius: 0.125rem;
  border-left: 0.25rem solid var(--color-blue);

  span {
    display: flex;
    align-items: center;
    color: lightgray;
    margin-right: 0.25rem;
    svg {
    }
  }

  h3 {
    font-weight: 500;
    color: whitesmoke;
  }
`;
