import { appPaths } from "./appPaths";

export const userNavPaths = {
  loggedOut: [
    {
      name: "Login",
      path: appPaths.login,
    },
    {
      name: "Register",
      path: appPaths.register,
    },
  ],
  loggedIn: [
    {
      name: "Me",
      path: appPaths.user,
    },
    {
      name: "Favorites",
      path: appPaths.userFavorites,
    },
    {
      name: "Upcoming",
      path: appPaths.userUpcoming,
    },
    {
      name: "Account",
      path: appPaths.user,
    },
    {
      name: "Logout",
      path: appPaths.logout,
    },
  ],
};
