// Movie.AgeRatingUS.choices=
//  [(1, 'NR'), (2, 'G'), (3, 'PG'), (4, 'PG-13'), (5, 'R'), (6, 'NC-17')]
// Movie.AgeRatingUS.labels=['NR', 'G', 'PG', 'PG-13', 'R', 'NC-17']
// Movie.AgeRatingUS.values=[1, 2, 3, 4, 5, 6]
// Movie.AgeRatingUS.names=['NR', 'G', 'PG', 'PG13', 'R', 'NC17']

export const ageRatingOptions = [
  { value: 2, label: "G" },
  { value: 3, label: "PG" },
  { value: 4, label: "PG-13" },
  { value: 5, label: "R" },
  { value: 6, label: "NC-17" },
  { value: 1, label: "NR" },
];

export const getAgeRatingLabel = (val) =>
  ageRatingOptions.find((option) => option.value === val).label;

// export const ageRatingOptions = ["G", "PG", "PG-13", "R", "NC-17", "NR"];

// export const ageRatingSelectOptions = [
//   { value: 1, label: "nr" },
//   { value: 2, label: "g" },
//   { value: 3, label: "pg" },
//   { value: 4, label: "pg-13" },
//   { value: 5, label: "r" },
//   { value: 6, label: "nc-17" },
// ];

export const TRYageRatingOptions = {
  G: { value: 2, label: "G" },
  PG: { value: 3, label: "PG" },
  PG13: { value: 4, label: "PG-13" },
  R: { value: 5, label: "R" },
  NC17: { value: 6, label: "NC-17" },
  NR: { value: 1, label: "NR" },
};
