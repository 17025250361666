import { useRef, useEffect } from "react";

function useRenderCount(renderer = "", showRenderCount = true) {
  const renderRef = useRef(0);
  useEffect(() => {
    renderRef.current = renderRef.current + 1;
  });
  useEffect(() => {
    if (showRenderCount) {
      console.info(`render (${renderer || "?"}): ${renderRef.current}`);
    }
  });
}

export { useRenderCount };
