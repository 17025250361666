import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useApiPaginated, useQueryParams, useRenderCount } from "../../hooks";
import { Toolbar, MovieList, LoadMoreButton } from "../../components";
import { Button } from "../../components/Toolbar";
import { Filters } from "./components";
import { releaseDateSortOptions } from "../../constants";
import { startOfPeriod, endOfPeriod } from "../../hooks/dates";

import { FaRegCalendar } from "react-icons/fa";
import { endpoints } from "../../api";

const cleanParams = ({ queryParams, removeParams = [] }) => {
  let qParams = {};
  for (const key in queryParams) {
    if (!removeParams.includes(key)) {
      qParams[key] = queryParams[key];
    }
  }
  return qParams;
};

const periods = {
  WEEK: "week",
  MONTH: "month",
};

const togglePeriod = (period) =>
  period === periods.WEEK ? periods.MONTH : periods.WEEK;

// const types = {
//   ALL: "release",
//   THEATRICAL: "theatrical",
//   DIGITAL: "digital",
//   PHYSICAL: "physical",
// };
const types = ["release", "theatrical", "digital", "physical"];

// export const releaseDateSortOptions = [`date`, `-rating`, `-votes`, `title`];
const isSortByDate = (sort) => sort === releaseDateSortOptions[0];

export default function ReleaseDates() {
  useRenderCount("ReleaseDates");
  const { type = types[0] } = useParams();

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: releaseDateSortOptions[0],
    period: periods.WEEK,
    date: startOfPeriod(new Date(), periods.WEEK),
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.movie.all,
    queryParams: cleanParams({ queryParams, removeParams: ["date", "period"] }),
    requestParams: {
      sort: isSortByDate(queryParams.sort) ? type : queryParams.sort,
      [`${type}_after`]: startOfPeriod(queryParams.date, queryParams.period),
      [`${type}_before`]: endOfPeriod(queryParams.date, queryParams.period),
    },
    enabled: !!queryParams.date,
  });

  const onSortChange = useCallback(
    (value) => updateQueryParams({ ...queryParams, sort: value }),
    [queryParams, updateQueryParams]
  );

  const onDateChange = (value) =>
    updateQueryParams({ ...queryParams, date: value });

  const onPeriodChange = (value) =>
    updateQueryParams({ ...queryParams, period: togglePeriod(value) });

  const goToCurrentPeriod = () =>
    onDateChange(startOfPeriod(new Date(), queryParams.period));

  return (
    <>
      <Toolbar
        title={"Release Dates"}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={releaseDateSortOptions}
        // sideButton={
        //   <Button onClick={goToCurrentPeriod}>
        //     {/*<FaRegCalendar />*/}
        //     Now
        //   </Button>
        // }
      >
        <Filters
          date={queryParams.date}
          onDateChange={onDateChange}
          period={queryParams.period}
          onPeriodChange={onPeriodChange}
          goToCurrentPeriod={goToCurrentPeriod}
          types={types.slice(1)}
          selected={type}
        />
      </Toolbar>

      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
        dateType={type}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
