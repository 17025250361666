import React from "react";
import { useParams } from "react-router-dom";
import { LoadMoreButton, MovieList, Toolbar } from "../../components";

import {
  useApi,
  useApiPaginated,
  useQueryParams,
  useRenderCount,
} from "../../hooks";
import { endpoints } from "../../api";
import { baseSortOptions } from "../../constants";

export default function ProductionCompanyDetail() {
  useRenderCount("ProductionCompanyDetail");
  const { slug } = useParams();
  const { data: prodcomp, prodcompStatus, prodcompError } = useApi({
    path: endpoints.productionCompany.detail(slug),
  });

  const [queryParams, updateQueryParams] = useQueryParams({
    sort: baseSortOptions[0],
  });

  const {
    data,
    status,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useApiPaginated({
    path: endpoints.productionCompany.movies(slug),
    queryParams: queryParams,
    pageSize: 30,
  });

  const onSortChange = (value) =>
    updateQueryParams({ ...queryParams, sort: value });

  return (
    <>
      <Toolbar
        title={prodcomp?.name}
        count={data?.count}
        sortValue={queryParams.sort}
        onSortSelect={onSortChange}
        sortOptions={baseSortOptions}
      />
      <MovieList
        movies={data?.items}
        isLoading={status === "loading"}
        isError={error}
      />
      <LoadMoreButton
        show={hasNextPage}
        onClick={() => fetchNextPage()}
        isLoading={isFetchingNextPage}
      />
    </>
  );
}
