import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useRenderCount } from "../../hooks";
import { FaUnlockAlt, FaUserCircle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Loader } from "../../components";

import {
  Wrap,
  FormWrap,
  Header,
  Form,
  InputGroup,
  Label,
  InputWrap,
  IconWrap,
  Input,
  Error,
  CheckboxGroup,
  Checkbox,
  AuthLink,
  SubmitButton,
  Detail,
} from "./components/styles";
import { appPaths } from "../../constants/appPaths";

const userRegistrationDefaultFields = {
  username: "",
  password: "",
  password2: "",
  email: "",
};

export default function Signup() {
  useRenderCount("Signup");
  let navigate = useNavigate();
  const { signup } = useAuth();
  const [user, setUser] = useState({
    ...userRegistrationDefaultFields,
  });
  const [errors, setErrors] = useState({
    ...userRegistrationDefaultFields,
    non_field_errors: "",
  });

  console.log("signup: ", signup);

  const onSignup = (e, user) => {
    e.preventDefault();
    setErrors(null);

    signup.mutate(
      { ...user },
      {
        onSuccess: async () => {
          console.log(`Signup: onSignup: onSuccess`);
          navigate(appPaths.user, { replace: true });
        },
        onError: (error, variables, context) => {
          console.log("error.response?.data: ", error.response?.data);
          setErrors({ ...error?.response?.data });
        },
      }
    );
  };

  // useEffect(() => {
  //   setErrors(null);
  // }, [user]);

  const handleChange = (e) => {
    const { target } = e;
    const name = target?.name;
    const value = target?.type === "checkbox" ? target?.checked : target?.value;
    setUser({ ...user, [name]: value });
  };

  return (
    <Wrap>
      <FormWrap>
        <Header>Register Account</Header>
        <Form method="POST" onSubmit={(e) => onSignup(e, user)}>
          <InputGroup>
            <Label htmlFor="username">Username</Label>
            <InputWrap hasError={errors?.username}>
              <IconWrap>
                <FaUserCircle />
              </IconWrap>
              <Input
                type="text"
                name="username"
                placeholder="Username"
                value={user?.username}
                onChange={handleChange}
              />
            </InputWrap>
            {errors?.username && <Error>{errors.username}</Error>}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password">Password</Label>
            <InputWrap hasError={errors?.password}>
              <IconWrap>
                <FaUnlockAlt />
              </IconWrap>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={user.password}
                onChange={handleChange}
              />
            </InputWrap>
            {/*{errors?.password && <Error>{errors.password}</Error>}*/}
            {errors?.password &&
              errors.password.map((error) => {
                return <Error key={error}>{error}</Error>;
              })}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password2">Verify Password</Label>
            <InputWrap hasError={errors?.password2}>
              <IconWrap>
                <FaUnlockAlt />
              </IconWrap>
              <Input
                type="password"
                name="password2"
                placeholder="Verify Password"
                value={user.password2}
                onChange={handleChange}
              />
            </InputWrap>
            {errors?.password2 && <Error>{errors.password2}</Error>}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="email">Email (optional)</Label>
            <InputWrap hasError={errors?.email}>
              <IconWrap>
                <MdEmail />
              </IconWrap>
              <Input
                type="text"
                name="email"
                placeholder="Email (optional)"
                value={user.email}
                onChange={handleChange}
                required={false}
              />
            </InputWrap>
            {errors?.email && <Error>{errors.email}</Error>}
          </InputGroup>

          <CheckboxGroup>
            <Checkbox
              name="remember"
              type="checkbox"
              checked={user.remember}
              onChange={handleChange}
            />
            <Label htmlFor="remember">Remember me</Label>
            <AuthLink to={appPaths.login}>Login</AuthLink>
          </CheckboxGroup>

          {errors?.nonField && <Error>{errors.nonField}</Error>}
          <SubmitButton
            type="submit"
            value="Register"
            disabled={!user.username || !user.password || !user.password2}
          >
            {signup.isLoading ? <Loader /> : "Register"}
          </SubmitButton>
        </Form>
        {/*<Detail>Test Credentials: test / test123</Detail>*/}
        <Detail>
          Coded by <strong>Matthew Hopps</strong>
        </Detail>
      </FormWrap>
    </Wrap>
  );
}
