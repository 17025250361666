import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { Link } from "react-router-dom";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { Section } from "./";

export default function Info({ movie }) {
  const {
    tagline,
    director,
    writer,
    budget,
    revenue,
    spoken_languages,
    production_companies,
  } = movie;
  return (
    <Section title="Info" icon={<BiPurchaseTagAlt />}>
      {tagline && (
        <Details>
          {/*<Detail>Tag</Detail>*/}
          <Name>{tagline}</Name>
          {/*{tagline}*/}
        </Details>
      )}
      {director?.length > 0 && (
        <Details>
          <Detail>{director.length > 1 ? "Directors" : "Director"}</Detail>
          <Stack>
            {director?.map((name) => (
              <Name key={`director-${name}`}>{name}</Name>
            ))}
          </Stack>
        </Details>
      )}
      {writer?.length > 0 && (
        <Details>
          <Detail>{writer.length > 1 ? "Writers" : "Writer"}</Detail>
          <Stack>
            {writer?.map((name) => (
              <Name key={`writer-${name}`}>{name}</Name>
            ))}
          </Stack>
        </Details>
      )}
      {spoken_languages?.length > 0 && (
        <Details>
          <Detail>
            {spoken_languages.length > 1 ? "Languages" : "Language"}
          </Detail>
          <Stack>
            {spoken_languages?.map((name) => (
              <Name key={`language-${name}`}>{name.split("-")[1]}</Name>
            ))}
          </Stack>
        </Details>
      )}
      {budget >= 0 && (
        <Details>
          <Detail>Budget</Detail>
          <Name>${budget?.toLocaleString()}</Name>
        </Details>
      )}
      {revenue >= 0 && (
        <Details>
          <Detail>Revenue</Detail>
          <Name>${revenue?.toLocaleString()}</Name>
        </Details>
      )}

      {production_companies?.length > 0 && (
        <Details>
          <Detail>
            {production_companies.length > 1
              ? "Production Companies"
              : "Production Company"}
          </Detail>
          <Stack>
            {production_companies?.map(
              ({ slug, name, origin_country, logo_url, total_movies }) => (
                <Name key={`pc-${slug}`}>
                  <Link to={`/production-company/${slug}`}>
                    {/*<img*/}
                    {/*  style={{ height: "30px", width: "30px" }}*/}
                    {/*  src={logo_url}*/}
                    {/*/>*/}
                    {origin_country
                      ? `${name} (${origin_country} | ${total_movies})`
                      : `${name} (${total_movies})`}
                  </Link>
                </Name>
              )
            )}
          </Stack>
        </Details>
      )}
    </Section>
  );
}

const Details = styled.div`
  display: flex;
  margin-top: 1rem;
  color: white;
`;

const Detail = styled.div`
  color: lightgray;
  width: 120px;
  align-self: flex-start;
  flex: 0 0 auto;
`;

const Name = styled.div`
  //color: white;
  margin: 0 0 0.5rem;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;
