import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Portal } from "../../";
import { useOnClickOutside } from "../../../hooks";
import { SearchIcon, SearchInputIcon } from "../../Icons";

import { motion, AnimatePresence } from "framer-motion";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    console.log("set search input focus");
    // htmlElRef.current && htmlElRef.current.focus();
    setTimeout(() => htmlElRef?.current?.focus(), 200);
    // htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

export default function Search() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [inputRef, setInputFocus] = useFocus();
  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () => setShowSearch(false));

  const show = () => {
    // console.log("show search");
    setShowSearch(true);
    // setInputFocus();
  };

  const onSearch = (e) => {
    e.preventDefault();
    setShowSearch(false);
    navigate(`/search/?search=${searchText}`);
  };

  const onCompletedMount = () => {
    setInputFocus();
  };

  return (
    <>
      <AnimatePresence>
        {showSearch && (
          <>
            <Wrap
              key="wrap"
              ref={searchRef}
              initial={{
                visibility: "hidden",
                opacity: 0,
                right: "-100vw",
              }}
              exit={{
                visibility: "hidden",
                opacity: 0,
                right: "-100vw",
              }}
              animate={{
                visibility: "visible",
                opacity: 1,
                right: 0,
              }}
              // onAnimationComplete={(def) => console.log("def: ", def)}
              // onLayoutAnimationComplete={() => setInputFocus()}
              // onAnimationComplete={() => setInputFocus()}
              onAnimationComplete={onCompletedMount}
            >
              <Form onSubmit={onSearch}>
                <SearchInputIcon />
                <input
                  ref={inputRef}
                  autoFocus={true}
                  type="text"
                  inputMode="search"
                  name="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <button type="submit" disabled={!searchText}>
                  Search
                </button>
              </Form>
            </Wrap>
            <Backdrop
              key="backdrop"
              initial={{
                visibility: "hidden",
                opacity: 0,
              }}
              exit={{
                visibility: "hidden",
                opacity: 0,
              }}
              animate={{
                visibility: "visible",
                opacity: 0.8,
              }}
            />
          </>
        )}
      </AnimatePresence>

      <NavButton onClick={show}>
        <SearchIcon />
      </NavButton>
    </>
  );
}

const NavButton = styled.button`
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  color: white;
  //border: 1px solid rgba(var(--color-blue-rgb), 0.375);
  background: rgba(255, 255, 255, 0.1);
  margin-right: 0.25rem;

  :hover {
    background: var(--color-blue);
  }
`;

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-charcoal);
  z-index: 1199;
`;

const Wrap = styled(motion.div)`
  //height: var(--header-height);
  height: calc(var(--header-height) - 2px);
  width: 100vw;
  max-width: 470px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  //flex-wrap: nowrap;

  position: absolute;
  top: 0;
  z-index: 1200;

  padding: 0 0.75rem;
  background: var(--color-charcoal);

  svg {
    //flex: 1;
    color: white;
    min-width: 22px;

    //position: absolute;
    //left: 20px;
    //z-index: 1201;
  }
`;

const Form = styled.form`
  max-width: 100%;
  width: 100vw;
  display: flex;
  align-items: center;

  // TODO: wrap svg & input in a white wrapper
  //  make input transparent, wrapper will look like input
  //  allowing svg (search icon) to appear inside of the input with padding away from svg
  //  search text starts after svg

  svg {
    //flex: 1;
    color: black;
    min-width: 22px;

    position: absolute;
    left: 25px;
    //z-index: 1201;
  }

  input {
    flex: 2 300px;
    appearance: none; // TODO: //for iOS input[type="search"] roundedness issue. border-radius alone doesn't work
    //border: none;
    border: 1px solid whitesmoke;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    outline: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    //overflow: hidden;
    height: 35px;
    //width: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding-left: 30px;
  }

  button {
    flex: 1;
    height: 35px;
    //background: white;
    //border: 1px solid lightgray;

    padding: 0.5rem 0.75rem;
    //margin-right: -0.125rem;
    color: white;
    //background: transparent;
    border: 1px solid rgba(var(--color-blue-rgb), 0.375);
    //background: rgba(var(--color-blue-rgb), 0.5);
    background: rgba(255, 255, 255, 0.1);
    //margin-right: 0.25rem;
  }
`;
