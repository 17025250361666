import React from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";

export default function Backdrop({ movie }) {
  const { backdrop_url } = movie;

  return (
    <BackdropWrap>
      <BackdropImage src={backdrop_url} />
      <div />
    </BackdropWrap>
  );
}

const BackdropWrap = styled.div`
  //position: absolute;
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  //height: 70vh;
  height: calc(100vh - var(--header-height));
  overflow: hidden;

  & > div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(10, 16, 22, 0.5);
    //background-image: linear-gradient(185deg, transparent, rgb(10, 16, 22) 95%),
    //  linear-gradient(transparent, rgb(10, 16, 22) 95%);

    //background-color: rgba(10, 16, 22, 0.75);
    //background-image: linear-gradient(185deg, transparent, rgb(10, 16, 22) 95%),
    //  linear-gradient(transparent, rgb(31, 35, 38) 95%);
    //box-shadow: inset 0 0 12px 4px rgba(10, 16, 22, 0.65);

    background-color: rgba(var(--color-charcoal-rgb), 0.75);
    background-image: linear-gradient(
        185deg,
        transparent,
        rgb(var(--color-charcoal-rgb)) 95%
      ),
      linear-gradient(transparent, rgb(var(--color-charcoal-rgb)) 95%);
    box-shadow: inset 0 0 12px 4px rgba(var(--color-charcoal-rgb), 0.65);

    //background: linear-gradient(0deg, #00000088 30%, #ffffff44 100%);
  }
`;

const BackdropImage = styled.img`
  object-position: center center;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
