import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { Loader } from "../../../components";
import { providerTypeOptions } from "../../../constants/providerTypes";

export default function ReleaseTypes({ types, onTypeChange, selected }) {
  console.log("types", types);
  // if (!types) return null;
  // if (!types) types = [];
  if (!types) return <Loader size={"1.75rem"} />;
  // return <Loader size={"1.75rem"} />;

  // const streamTypes = providerTypeOptions.filter((item, index, array) => {
  //   return types ? types.includes(item.value) : [0];
  // });
  // console.log("streamTypes", streamTypes);

  return (
    <Bar>
      <Title>Type</Title>
      {(types || []).map((type) => (
        <Tab
          key={type}
          isSelected={type === selected}
          onClick={() => onTypeChange(type)}
        >
          {type.toLowerCase()}
        </Tab>
      ))}
    </Bar>
  );
}

const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.75rem;
  //max-width: 400px;

  @media ${device.min.tablet} {
    justify-content: center;
    column-gap: 0.75rem;
  }
`;

const Title = styled.h3`
  display: none;
  color: gray;
  font-size: 1rem;
  font-weight: 400;
  //margin-right: auto;

  @media ${device.min.tablet} {
    display: flex;
    justify-self: flex-start;
  }
`;

const Tab = styled.button`
  display: flex;
  //margin: 0 0.25rem;
  border-radius: 0.75rem;
  text-transform: capitalize;

  padding: ${({ isSelected }) =>
    isSelected ? "0.25rem 0.75rem" : "0.25rem 0.75rem"};
  color: ${({ isSelected }) =>
    isSelected ? "white" : "var(--color-charcoal)"};
  background: ${({ isSelected }) =>
    isSelected ? "rgba(var(--color-blue-rgb), 0.75)" : "whitesmoke"};
  border: ${({ isSelected }) =>
    isSelected ? "1px solid var(--color-blue)" : "1px solid lightgray"};
`;

// const Tag = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 0.25rem;
//   padding: 0.125rem 0.375rem;
//   border-radius: 0.375rem;
//   font-size: 0.9rem;
//   text-transform: capitalize;
//   color: var(--color-charcoal);
//   background: whitesmoke;
//
//   svg {
//     color: var(--color-charcoal-light);
//     margin-right: 0.25rem;
//   }
// `;
