import React, { useState, useMemo } from "react";
import styled from "styled-components/macro";
import { device } from "../../../constants/devices";
import { useAuth, useMutateMovie } from "../../../hooks";
import { FaEye, FaHeart, FaYoutube } from "react-icons/fa";
import { Loader, Portal } from "../../../components";
import { FiEye, FiHeart } from "react-icons/fi";
import { IoRefresh } from "react-icons/io5";

/*
- Button List: trailer, favorite, watch list, homepage link?, share?
 */

const FavoriteIcon = ({ isFav, isLoading }) => {
  // {isLoading ? "Loading" : isFavorite ? "Favorite" : "Favorite"}
  return isLoading ? <Loader /> : isFav ? <FaHeart /> : <FiHeart />;
};

const RefreshIcon = ({ isLoading }) => {
  return isLoading ? <Loader /> : <IoRefresh />;
};

const Trailer = ({ show, close, title, url }) => {
  // const trailerRef = useRef();
  return (
    <TrailerWrap show={show}>
      <CloseTrailerButton onClick={close}>X</CloseTrailerButton>
      <iframe
        title={title}
        data-src={`${url}?autoplay=1&controls=1&modestbranding=1&playsinline=0&rel=0&start=1`}
        // data-src={`${trailer_url}?autoplay=1&controls=1&modestbranding=1&playsinline=0&rel=0`}
        frameBorder="0"
        className="lazyload"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </TrailerWrap>
  );
};

export default function ButtonBar({ movie }) {
  const { favorite, refresh } = useMutateMovie();
  const { user } = useAuth();
  const [showTrailer, setShowTrailer] = useState(false);

  const { slug, trailer_url, title, homepage_url } = movie;

  const isFav = useMemo(() => {
    return user?.favorites?.includes(slug);
  }, [user, slug]);

  const toggleFav = () => favorite.mutate({ isFav, slug });
  const refreshData = () => refresh.mutate({ slug });

  return (
    <Wrap>
      <TrailerButton onClick={() => setShowTrailer(true)}>
        <FaYoutube /> Trailer
      </TrailerButton>
      <Portal>
        {showTrailer && (
          <Trailer
            show={showTrailer}
            close={() => setShowTrailer(false)}
            title={title}
            url={trailer_url}
          />
        )}
      </Portal>
      {user && (
        <ButtonWrap>
          <Button onClick={toggleFav} isFav={isFav}>
            <FavoriteIcon isFav={isFav} isLoading={favorite.isLoading} />
          </Button>
          <Button onClick={refreshData}>
            <RefreshIcon isLoading={refresh.isLoading} />
          </Button>
        </ButtonWrap>
      )}
    </Wrap>
  );
}

const CloseTrailerButton = styled.button`
  position: fixed;
  color: red;
  top: 4rem;
  right: 2rem;
  padding: 1rem;
  font-size: 2rem;
  background: whitesmoke;
`;

const TrailerWrap = styled.div`
  z-index: 9000;
  position: fixed;
  //top: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background: var(--color-charcoal);
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;

  @media ${device.min.small} {
    margin-left: 1.5rem;
    justify-content: flex-start;
  }
`;

const Button = styled.button`
  //font-size: 1.25rem;
  margin-right: 1rem;
  padding: 0.75rem;
  color: white;
  background: rgba(0, 0, 0, 0.25);
  //border: 1px solid rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(var(--color-blue-rgb), 0.375);

  svg {
    color: ${({ isFav }) => (isFav ? "var(--color-blue)" : "inherit")};
  }

  :hover {
    color: white;
    svg {
      color: white;
    }
    background: rgba(var(--color-blue-rgb), 0.95);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const TrailerButton = styled.button`
  padding: 0.5rem 0.75rem;
  width: 160px;
  color: white;
  background: rgba(0, 0, 0, 0.25);
  //border: 1px solid rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(var(--color-blue-rgb), 0.375);

  svg {
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }

  @media ${device.min.tablet} {
    width: 250px;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin-bottom: -2rem;
`;
