import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
// import { device } from "../devices";
import {
  isEqual,
  formatTagDate,
  eachPeriodOfInterval,
  urlToDate,
  dateToUrl,
} from "../../../hooks/dates";
import { useRenderCount } from "../../../hooks";
import { ArrowLeft, ArrowRight } from "../../../components/Icons";

import { Button } from "../../../components/Toolbar";

const makeDatePagerObject = (date, period) => {
  const count = 1;
  const datesList = eachPeriodOfInterval(date, period, count);
  return {
    prev: dateToUrl(datesList[0]),
    current: dateToUrl(datesList[1]),
    next: dateToUrl(datesList[2]),
  };
};

const useDates = ({ date, period }) => {
  const [dates, setDates] = useState(() => makeDatePagerObject(date, period));

  useEffect(() => {
    console.log(`setting dates for: \ndate: ${date} - (period: ${period})`);
    setDates(makeDatePagerObject(date, period));
  }, [date, period]);

  return { dates };
};

export default function DatePager({
  date,
  onDateChange,
  period,
  onPeriodChange,
}) {
  useRenderCount("DatePicker");
  const { dates } = useDates({ date, period });
  // console.log("date: ", date);
  // console.log("period: ", period);
  console.log("dates: ", dates);

  return (
    <Wrap>
      <Date
        key={formatTagDate(dates.current, period)}
        onClick={() => onDateChange(dates.current)}
      >
        {formatTagDate(dates.current, period)}
      </Date>

      <ButtonWrap>
        <Button onClick={() => onDateChange(dates.prev)}>
          <ArrowLeft />
        </Button>
        <Button onClick={() => onDateChange(dates.next)}>
          <ArrowRight />
        </Button>
        <ToggleButton onClick={() => onPeriodChange(period)}>
          {period}
        </ToggleButton>
      </ButtonWrap>
    </Wrap>
  );
}

const Wrap = styled.div`
  //border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;

  //height: 40px;
  //width: 100%;
  //max-width: 400px;
`;

const ButtonWrap = styled.div`
  //border: 1px solid orange;
  display: flex;
`;

const Date = styled.h3`
  //flex: 1;
  //margin: 0 0.25rem;
  //padding: 0.375rem;

  //text-transform: capitalize;
  //border: 1px solid orange;
`;

const ToggleButton = styled(Button)`
  min-width: 75px;
  text-transform: capitalize;
`;

// const Button = styled.button`
//   border: 1px solid lightgray;
//   margin: 0 0 0 0.5rem;
//   padding: 0.375rem;
//
// text-transform: capitalize;
// `;
