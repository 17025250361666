import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUnlockAlt, FaUserCircle } from "react-icons/fa";

import { useAuth, useRenderCount } from "../../hooks";
import { Loader } from "../../components";
import {
  Wrap,
  FormWrap,
  Header,
  Form,
  InputGroup,
  Label,
  InputWrap,
  IconWrap,
  Input,
  Error,
  CheckboxGroup,
  Checkbox,
  AuthLink,
  SubmitButton,
  Detail,
} from "./components/styles";
import { appPaths } from "../../constants/appPaths";

export default function Login() {
  useRenderCount("Login");
  const navigate = useNavigate();
  const location = useLocation();
  // get last location to send back || home
  // TODO: from not working - location.state is not set (is null)
  const { from } = location.state || { from: { pathname: "/" } };
  console.log("Login: location: ", location);
  console.log("Login: from: ", from);

  const { signin } = useAuth();
  const [user, setUser] = useState({
    username: "",
    password: "",
    remember: true,
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    non_field_errors: "",
  });

  console.log("signin: ", signin);

  const onSignin = async (e, user) => {
    e.preventDefault();
    console.log("Login: onSignin: user: ", user);
    signin.mutate(
      { ...user },
      {
        onSuccess: (data, variables, context) => {
          console.log("Login: onSignin: onSuccess: from: ", from);
          navigate(from, { replace: true });
          // welcome message for ${data.username}
        },
        onError: (error, variables, context) => {
          console.log("error.response?.data: ", error.response?.data);
          setErrors({ ...error?.response?.data });
        },
      }
    );
  };

  const handleChange = (e) => {
    const { target } = e;
    const name = target?.name;
    const value = target?.type === "checkbox" ? target?.checked : target?.value;
    setUser({ ...user, [name]: value });
  };

  return (
    <Wrap>
      <FormWrap>
        <Header>Login Account</Header>
        {/*<Detail>Test Credentials: test / test123</Detail>*/}
        {/*<Detail>Data resets daily</Detail>*/}

        <Form method="POST" onSubmit={(e) => onSignin(e, user)}>
          {/* would need to pass alot of props to make a reusable input */}
          {/* username, icon, type, Username, user.username, errors.username, handleChange */}
          <InputGroup>
            <Label htmlFor="username">Username</Label>
            <InputWrap hasError={errors?.username}>
              <IconWrap>
                <FaUserCircle />
              </IconWrap>
              <Input
                type="text"
                name="username"
                placeholder="Username"
                value={user.username}
                onChange={handleChange}
              />
            </InputWrap>
            {errors?.username && <Error>{errors.username}</Error>}
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password">Password</Label>
            <InputWrap hasError={errors?.password}>
              <IconWrap>
                <FaUnlockAlt />
              </IconWrap>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={user.password}
                onChange={handleChange}
              />
            </InputWrap>
            {errors?.password && <Error>{errors.password}</Error>}
          </InputGroup>

          <CheckboxGroup>
            <Checkbox
              name="remember"
              type="checkbox"
              checked={user.remember}
              onChange={handleChange}
            />
            <Label htmlFor="remember">Remember me</Label>
            <AuthLink to={appPaths.register}>Register</AuthLink>
          </CheckboxGroup>

          {errors?.non_field_errors && <Error>{errors.non_field_errors}</Error>}
          <SubmitButton
            type="submit"
            disabled={!user?.username || !user?.password}
          >
            {signin.isLoading ? <Loader /> : "Login"}
          </SubmitButton>
        </Form>
        {/*<Detail>Test Credentials: test / test123</Detail>*/}
        <Detail>
          Coded by <strong>Matthew Hopps</strong>
        </Detail>
      </FormWrap>
    </Wrap>
  );
}
