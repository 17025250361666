import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { useAuth, useLocalStorage, useRenderCount } from "../../hooks";

export default function Details() {
  useRenderCount("Details");
  const { user } = useAuth();
  const [view, setView] = useLocalStorage("list-type", "list");

  return (
    <UserInfo>
      <h1>{user.username}</h1>
      <Link to={"/me/favorites"}>
        Favorite Movies (#{user.favorites.length})
      </Link>
      <details>
        <summary>User Details</summary>
        <p>{user.id}</p>
        <p>Last Login: {user.last_login}</p>
        <p>Email: {user.email}</p>
        <p>Slug: {user.slug}</p>
      </details>
      <ListType type={view}>
        <p>Display movies as</p>
        <div>
          <Button selected={view === "list"} onClick={() => setView("list")}>
            List
          </Button>
          <Button
            selected={view === "poster"}
            onClick={() => setView("poster")}
          >
            Poster
          </Button>
        </div>
      </ListType>
    </UserInfo>
  );
}

const Button = styled.button`
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--color-blue);
  color: ${({ selected }) => (selected ? "white" : "inherit")};
  background: ${({ selected }) => (selected ? "var(--color-blue)" : "white")};
`;

const ListType = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  p {
    margin-bottom: 0.5rem;
  }

  & > div {
    width: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const UserInfo = styled.div`
  grid-area: details;
  padding: 1rem;
  background: white;
  color: grey;

  & > h1 {
    color: var(--color-charcoal);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }

  & > a {
    color: rgba(var(--color-blue-rgb), 0.5);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  //details {
  //  margin
  //}
`;
