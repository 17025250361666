import styled from "styled-components/macro";
import { Brand, Search, MobileNav, UserMenu, HeaderNav } from "./components";

// TODO: <Link>s should be <NavLink> to get isActive prop

export default function HeaderWithSearch() {
  return (
    <>
      <Wrap>
        <Brand />
        <HeaderNav />
        <ButtonWrap>
          <Search />
          <UserMenu />
        </ButtonWrap>
      </Wrap>
      <Filler />
      <MobileNav />
    </>
  );
}

const Filler = styled.div`
  /* push content below (position: fixed) header */
  height: var(--header-height);
`;

const Wrap = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: var(--max-content-width);
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  background: var(--color-charcoal);
  border-bottom: 0.125rem solid var(--color-blue);
  z-index: 10;
`;

const ButtonWrap = styled.div`
  display: flex;
  height: 35px;
`;
